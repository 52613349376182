/**
 * Generated by oas-generator
 * Do not modify
 */

import { Metadata } from './Metadata';
import { UserSubmission } from './UserSubmission';
import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface UserResponseList {
  data?: Array<UserSubmission>;
  metadata?: Metadata;
}

// schema should have type JSONSchemaType<UserResponseList>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    data: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          userId: { type: 'string' },
          userName: { type: 'string' },
          response: {
            type: 'object',
            properties: {
              value: {
                type: ['integer', 'null'],
                format: 'int64',
                minimum: -9223372036854776000,
                maximum: 9223372036854776000,
              },
              isCorrect: { type: 'boolean' },
              hasSubmitted: { type: 'boolean' },
              submittedOn: { type: ['string', 'null'], format: 'date-time' },
            },
          },
        },
      },
    },
    metadata: {
      type: 'object',
      properties: {
        page: { type: 'integer', description: 'Current page of results' },
        perPage: { type: 'integer', description: 'Maximum results per page' },
        pageCount: {
          type: 'integer',
          description: 'Number of results returned for this page',
        },
        totalCount: { type: 'integer', description: 'Total number of results' },
        totalPages: { type: 'integer', description: 'Total number of pages' },
        sort: { type: 'string' },
        order: { type: 'string', enum: ['asc', 'desc'] },
      },
    },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isUserResponseList = ajv.compile<UserResponseList>(schema);
