<ng-container *ngIf="overlay; else inlinespinner">
  <div
    class="etool-spinner__overlay"
    cdkTrapFocus
    tabIndex="0"
    [cdkTrapFocusAutoCapture]="true">
    <div class="etool-spinner__container">
      <div
        class="spinner-border text-primary etool-spinner__icon"
        role="status">
        <span class="visually-hidden">{{ "LOADING" | translate }}</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #inlinespinner>
  <div class="etool-spinner__container etool-spinner__container--inline">
    <div class="spinner-border text-primary etool-spinner__icon" role="status">
      <span class="visually-hidden">{{ "LOADING" | translate }}</span>
    </div>
  </div>
</ng-template>
