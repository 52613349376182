/**
 * Generated by oas-generator
 * Do not modify
 */

import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface ApiErrorResponse {
  message?: string;
  errorCode?: string;
  timestamp?: string;
  trackId?: string;
}

// schema should have type JSONSchemaType<ApiErrorResponse>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    message: { type: 'string' },
    errorCode: { type: ['string', 'null'] },
    timestamp: { type: 'string', format: 'date-time' },
    trackId: { type: 'string' },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isApiErrorResponse = ajv.compile<ApiErrorResponse>(schema);
