/**
 * Generated by oas-generator
 * Do not modify
 */

import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface ExternalQuestionId {
  collectionId?: string;
  questionId?: string;
}

// schema should have type JSONSchemaType<ExternalQuestionId>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    collectionId: { type: 'string' },
    questionId: { type: 'string' },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isExternalQuestionId = ajv.compile<ExternalQuestionId>(schema);
