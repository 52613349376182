export interface EtoolToast {
  message: string;
  header?: string;
  id?: string;
  options?: EtoolToastOptions;
  type?: ToastType;
}

export interface EtoolToastOptions {
  className: string;
  /**
   * milliseconds until toast hides
   * default value is 8000
   */
  delay?: number;

  /**
   * will the toast stay on the screen indefinitely
   */
  persist?: boolean;
}

export enum ToastType {
  success = 'SUCCESS',
  error = 'ERROR',
  warning = 'WARNING',
  info = 'INFO',
}
