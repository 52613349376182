<ng-container *ngFor="let toast of toastsService.toasts; index as i">
  <ngb-toast
    *ngIf="toast.type === toastType.error"
    [class]="toast.options.className"
    [autohide]="!toast.options?.persist"
    [delay]="toast.options?.delay || 8 * 1000"
    [header]="''"
    (hidden)="removeToast(toast)"
    (shown)="focusOnToastCloseButton()"
    (keydown)="onToastKeyDown($event, i)">
    <ng-template ngbToastHeader>
      <div class="et-toast-header et-toast-header--danger">
        <div class="et-toast-header__icon">
          <mhicon-x-circle></mhicon-x-circle>
        </div>
        <div class="et-toast-header__title">{{ toast.header | translate }}</div>
      </div>
    </ng-template>
    {{ toast.message | translate }}
  </ngb-toast>

  <ngb-toast
    *ngIf="toast.type === toastType.success"
    [class]="toast.options.className"
    [autohide]="!toast.options?.persist"
    [delay]="toast.options?.delay || 8 * 1000"
    [header]="''"
    (hidden)="removeToast(toast)"
    (shown)="focusOnToastCloseButton()"
    (keydown)="onToastKeyDown($event, i)">
    <ng-template ngbToastHeader>
      <div class="et-toast-header et-toast-header--success">
        <div class="et-toast-header__icon">
          <mhicon-check-circle></mhicon-check-circle>
        </div>
        <div class="et-toast-header__title">{{ toast.header | translate }}</div>
      </div>
    </ng-template>
    {{ toast.message | translate }}
  </ngb-toast>

  <ngb-toast
    *ngIf="toast.type === toastType.warning"
    [class]="toast.options.className"
    [autohide]="!toast.options?.persist"
    [delay]="toast.options?.delay || 8 * 1000"
    [header]="''"
    (hidden)="removeToast(toast)"
    (shown)="focusOnToastCloseButton()"
    (keydown)="onToastKeyDown($event, i)">
    <ng-template ngbToastHeader>
      <div class="et-toast-header et-toast-header--warning">
        <div class="et-toast-header__icon">
          <mhicon-exclamation-triangle-mh></mhicon-exclamation-triangle-mh>
        </div>
        <div class="et-toast-header__title">{{ toast.header | translate }}</div>
      </div>
    </ng-template>
    {{ toast.message | translate }}
  </ngb-toast>
  <ngb-toast
    *ngIf="toast.type === toastType.info"
    [class]="toast.options.className"
    [autohide]="!toast.options?.persist"
    [delay]="toast.options?.delay || 8 * 1000"
    [header]="''"
    (hidden)="removeToast(toast)"
    (shown)="focusOnToastCloseButton()"
    (keydown)="onToastKeyDown($event, i)">
    <ng-template ngbToastHeader>
      <div class="et-toast-header et-toast-header--info">
        <div class="et-toast-header__icon">
          <mhicon-info-circle></mhicon-info-circle>
        </div>
        <div class="et-toast-header__title">{{ toast.header | translate }}</div>
      </div>
    </ng-template>
    {{ toast.message | translate }}
  </ngb-toast>
</ng-container>
