import { createSelector } from '@ngrx/store';

import { AppState } from '../state/app.state';
import { LoadingState } from '../state/loading.state';

const loadingState = (state: AppState) => state.loading;

export const getAppSpinner = createSelector(
  loadingState,
  (state: LoadingState) => state.isAppLoading
);

export const getPollWidgetSpinner = createSelector(
  loadingState,
  (state: LoadingState) => state.isPollWidgetLoading
);
