import { Injectable } from '@angular/core';
import { EtoolToast, ToastType } from './toast.interface';

/**
 * @see ToastComponent
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ToastsService {
  constructor() {}

  toasts: EtoolToast[] = [];

  show(toastConfig: EtoolToast) {
    // don't add the toast if there is already a toast that has the same id.
    if(this.toasts.length && toastConfig.id) {
      const toastExists: EtoolToast | undefined = this.toasts.find(toast => toast.id === toastConfig.id);
      if (toastExists) {
        return;
      }
    }
    this.toasts.push(toastConfig);
  }

  error(toastConfig?: EtoolToast) {
    let toast = toastConfig;

    toast.type = ToastType.error;
    if (!toast.header) {
      toast.header = 'ERROR';
    }
    if (toast.options) {
      toast.options.className += ' translated-paragraph bg-danger text-light';
    } else {
      toast.options = {
        className: 'translated-paragraph bg-danger text-light',
      };
    }
    toast.options.persist = true;
    this.show(toast);
  }

  success(toastConfig?: EtoolToast) {
    let toast = toastConfig;
    toast.type = ToastType.success;
    if (!toast.header) {
      toast.header = 'SUCCESS';
    }
    if (toast.options) {
      toast.options.className += ' translated-paragraph bg-success text-light';
    } else {
      toast.options = {
        className: 'translated-paragraph bg-success text-light',
      };
    }
    this.show(toast);
  }

  warning(toastConfig?: EtoolToast) {
    let toast = toastConfig;
    toast.type = ToastType.warning;
    if (!toast.header) {
      toast.header = 'WARNING';
    }
    if (toast.options) {
      toast.options.className += ' translated-paragraph bg-warning text-dark';
    } else {
      toast.options = {
        className: 'translated-paragraph bg-warning text-dark',
      };
    }
    this.show(toast);
  }

  info(toastConfig?: EtoolToast) {
    let toast = toastConfig;
    toast.type = ToastType.info;
    if (!toast.header) {
      toast.header = 'INFO';
    }
    if (toast.options) {
      toast.options.className += ' translated-paragraph bg-primary text-light';
    } else {
      toast.options = {
        className: 'translated-paragraph bg-primary text-light',
      };
    }
    this.show(toast);
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts = [];
  }
}
