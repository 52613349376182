/**
 * Generated by oas-generator
 * Do not modify
 */

import { FullConfig } from '../config';
import {
  InvalidResponseStatusCode,
  ResponseStatusCodeOutOfRange,
  ResponseValidationError,
  TimeoutError,
} from '../errors';
import { retryRequest } from '../http/retryRequest';
import {
  HttpStatusCode,
  isHttpStatusCode,
  SDKResponse,
  SDKResponseValidation,
} from '../http/SDKResponse';
import { wrapErrors } from '../http/wrapErrors';
import { AnswerChoice } from '../models/AnswerChoice';
import { ExternalQuestionId } from '../models/ExternalQuestionId';
import { parseResponse } from '../parseResponse';
import { HttpClient, HttpRequest } from '@angular/common/http';
import Ajv, { ErrorObject, ValidateFunction } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';
import instanceofDef from 'ajv-keywords/dist/definitions/instanceof';
import { identity, mergeMap, Observable, throwError, timeout } from 'rxjs';

// Teach AJV about the Blob type
instanceofDef.CONSTRUCTORS['Blob'] = Blob;

export interface UpdateQuestionParameters {
  id: number;
}

export interface UpdateQuestionRequest extends UpdateQuestionParameters {
  _content: {
    externalQuestionId?: ExternalQuestionId;
    type?: string;
    stem?: string;
    title?: string;
    choices?: Array<AnswerChoice>;
    status?: 'OPEN' | 'CLOSED' | 'LOCKED' | null;
    id?: number;
    createdOn?: string;
    updatedOn?: string;
    tries?: number;
  };
}

export type UpdateQuestionResponse = SDKResponse<UpdateQuestionResponseMap>;

type UpdateQuestionResponseMap = {
  '200': {
    externalQuestionId?: ExternalQuestionId;
    type?: string;
    stem?: string;
    title?: string;
    choices?: Array<AnswerChoice>;
    status?: 'OPEN' | 'CLOSED' | 'LOCKED' | null;
    id?: number;
    createdOn?: string;
    updatedOn?: string;
    tries?: number;
  };
  '400': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '401': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '403': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '405': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '500': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  default: void;
};

const ajv = new Ajv();
addKeywords(addFormats(ajv));
const responseValidators: Record<string, ValidateFunction> & {
  default: ValidateFunction;
} = {
  '200': ajv.compile<UpdateQuestionResponseMap['200']>({
    type: 'object',
    properties: {
      externalQuestionId: {
        allOf: [
          {
            type: 'object',
            properties: {
              collectionId: { type: 'string' },
              questionId: { type: 'string' },
            },
          },
        ],
      },
      type: { type: 'string' },
      stem: { type: 'string' },
      title: { type: 'string' },
      choices: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            value: { type: 'string' },
            isCorrect: { type: 'boolean' },
          },
        },
      },
      status: {
        type: ['string', 'null'],
        enum: ['OPEN', 'CLOSED', 'LOCKED', null],
      },
      id: {
        type: ['integer', 'null'],
        format: 'int64',
        minimum: -9223372036854776000,
        maximum: 9223372036854776000,
      },
      createdOn: { type: ['string', 'null'], format: 'date-time' },
      updatedOn: { type: ['string', 'null'], format: 'date-time' },
      tries: {
        type: ['integer', 'null'],
        format: 'int32',
        minimum: -2147483648,
        maximum: 2147483647,
      },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '400': ajv.compile<UpdateQuestionResponseMap['400']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '401': ajv.compile<UpdateQuestionResponseMap['401']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '403': ajv.compile<UpdateQuestionResponseMap['403']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '405': ajv.compile<UpdateQuestionResponseMap['405']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '500': ajv.compile<UpdateQuestionResponseMap['500']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  default: ajv.compile<unknown>({}),
};

function isUpdateQuestionResponse(target: {
  statusCode: HttpStatusCode;
  content: unknown;
}): target is SDKResponseValidation<UpdateQuestionResponseMap> {
  return (
    responseValidators[target.statusCode] ?? responseValidators['default']
  )(target.content);
}
function lastValidationError(statusCode: HttpStatusCode): ErrorObject[] {
  return (
    (responseValidators[statusCode] ?? responseValidators['default']).errors ??
    []
  );
}

export function updateQuestion(
  http: HttpClient,
  request: UpdateQuestionRequest,
  config: FullConfig
): Observable<UpdateQuestionResponse> {
  const renderedPath = '/api/v1/polls/questions/{id}'.replace(
    '{id}',
    String(request['id'])
  );
  const fullUrl = config.baseUrl + renderedPath;

  const options: Parameters<HttpClient['request']>[2] = {
    responseType: 'blob',
    observe: 'response',
  };
  options.body = request._content;

  // Headers
  const headerParams: Record<string, string> = {};

  if (config.enableAuthentication && config.tokenSource !== null) {
    headerParams['Authorization'] = `Bearer ${config.tokenSource()}`;
  }
  options.headers = headerParams;

  return http.request('PUT', fullUrl, options).pipe(
    config.timeoutInSeconds > 0
      ? timeout({
          first: config.timeoutInSeconds * 1000,
          with: () =>
            throwError(
              new TimeoutError({
                url: fullUrl,
                method: 'PUT',
                timeoutInSeconds: config.timeoutInSeconds,
              })
            ),
        })
      : identity,
    wrapErrors('PUT', fullUrl),
    retryRequest('PUT', config),
    mergeMap(async (response) => {
      const statusCode = String(response.status);
      if (!isHttpStatusCode(statusCode)) {
        throw new InvalidResponseStatusCode('put', fullUrl, statusCode);
      }

      let content: unknown = undefined;

      switch (statusCode) {
        case '200':
          content = await parseResponse(response.body, {
            method: 'put',
            url: fullUrl,
          });
          break;
        case '400':
          content = await parseResponse(response.body, {
            method: 'put',
            url: fullUrl,
          });
          break;
        case '401':
          content = await parseResponse(response.body, {
            method: 'put',
            url: fullUrl,
          });
          break;
        case '403':
          content = await parseResponse(response.body, {
            method: 'put',
            url: fullUrl,
          });
          break;
        case '405':
          content = await parseResponse(response.body, {
            method: 'put',
            url: fullUrl,
          });
          break;
        case '500':
          content = await parseResponse(response.body, {
            method: 'put',
            url: fullUrl,
          });
          break;
        default:
          content = undefined;
          break;
      }

      const responseParts = { statusCode, content };
      if (!isUpdateQuestionResponse(responseParts)) {
        throw new ResponseValidationError(
          'put',
          renderedPath,
          lastValidationError(statusCode),
          statusCode,
          response.body
        );
      }

      config.throwStatus.forEach((range) => {
        if (range.min <= response.status && response.status <= range.max) {
          throw new ResponseStatusCodeOutOfRange({
            body: response.body ?? new Blob(),
            method: 'put',
            url: fullUrl,
            responseStatusCode: statusCode,
          });
        }
      });

      const headers = response.headers
        .keys()
        .reduce((acc: Record<string, string>, key: string) => {
          const val = response.headers.get(key);
          if (val !== null) {
            acc[key] = val;
          }
          return acc;
        }, {} as Record<string, string>);
      return {
        ...responseParts,
        raw: { body: response.body, headers },
      };
    })
  );
}
