import { Action } from '@ngrx/store';

export enum LoadingActions {
  AppSpinner = '[Loading] Show app spinner',
  PollWidgetSpinner = '[Loading] Show Poll widget spinner',
}

export class AppSpinnerAction implements Action {
  public readonly type = LoadingActions.AppSpinner;
  constructor(public payload: boolean) {}
}

export class PollWidgetSpinnerAction implements Action {
  public readonly type = LoadingActions.PollWidgetSpinner;
  constructor(public payload: boolean) {}
}

export type LoadingActionsUnion = AppSpinnerAction | PollWidgetSpinnerAction;
