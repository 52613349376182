/**
 * Generated by oas-generator
 * Do not modify
 */

import { FullConfig } from '../config';
import {
  InvalidResponseStatusCode,
  ResponseStatusCodeOutOfRange,
  ResponseValidationError,
  TimeoutError,
} from '../errors';
import { retryRequest } from '../http/retryRequest';
import {
  HttpStatusCode,
  isHttpStatusCode,
  SDKResponse,
  SDKResponseValidation,
} from '../http/SDKResponse';
import { wrapErrors } from '../http/wrapErrors';
import { Metadata } from '../models/Metadata';
import { Poll } from '../models/Poll';
import { parseResponse } from '../parseResponse';
import { HttpClient, HttpRequest } from '@angular/common/http';
import Ajv, { ErrorObject, ValidateFunction } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';
import instanceofDef from 'ajv-keywords/dist/definitions/instanceof';
import { identity, mergeMap, Observable, throwError, timeout } from 'rxjs';

// Teach AJV about the Blob type
instanceofDef.CONSTRUCTORS['Blob'] = Blob;

export interface GetPollsParameters {
  contextXid?: string;
  page?: number;
  perPage?: number;
  sort?: 'title' | 'date' | 'question_count' | 'status';
  order?: 'asc' | 'desc';
  title?: string;
}

export interface GetPollsRequest extends GetPollsParameters {}

export type GetPollsResponse = SDKResponse<GetPollsResponseMap>;

type GetPollsResponseMap = {
  '200': {
    data?: Array<Poll>;
    metadata?: Metadata;
  };
  '400': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '401': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '403': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '405': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '500': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  default: void;
};

const ajv = new Ajv();
addKeywords(addFormats(ajv));
const responseValidators: Record<string, ValidateFunction> & {
  default: ValidateFunction;
} = {
  '200': ajv.compile<GetPollsResponseMap['200']>({
    type: 'object',
    properties: {
      data: {
        type: 'array',
        items: {
          type: 'object',
          required: ['title'],
          properties: {
            id: {
              type: 'integer',
              format: 'int64',
              minimum: -9223372036854776000,
              maximum: 9223372036854776000,
            },
            createdOn: { type: 'string', format: 'date-time' },
            updatedOn: { type: 'string', format: 'date-time' },
            title: { type: 'string' },
            showQuestionsInorder: { type: 'boolean' },
            allowAnonymousResponse: { type: 'boolean' },
            allowStudentViewResponse: { type: 'boolean' },
            allowStudentViewCorrectAnswer: { type: 'boolean' },
            displayCorrectAnswerInConductPollView: { type: 'boolean' },
            scoreForAttendance: { type: 'boolean' },
            scoreForParticipation: { type: 'boolean' },
            activeQuestion: {
              type: ['integer', 'null'],
              format: 'int64',
              minimum: -9223372036854776000,
              maximum: 9223372036854776000,
            },
            questions: {
              type: ['array', 'null'],
              items: {
                type: 'object',
                properties: {
                  id: {
                    type: 'integer',
                    format: 'int64',
                    minimum: -9223372036854776000,
                    maximum: 9223372036854776000,
                  },
                  createdOn: { type: 'string', format: 'date-time' },
                  updatedOn: { type: 'string', format: 'date-time' },
                  externalQuestionId: {
                    type: 'object',
                    properties: {
                      collectionId: { type: 'string' },
                      questionId: { type: 'string' },
                    },
                  },
                  source: {
                    anyOf: [
                      {},
                      {
                        type: 'object',
                        properties: {
                          id: {
                            type: 'integer',
                            format: 'int64',
                            minimum: -9223372036854776000,
                            maximum: 9223372036854776000,
                          },
                          createdOn: { type: 'string', format: 'date-time' },
                          updatedOn: { type: 'string', format: 'date-time' },
                          sourceId: { type: 'string' },
                          name: { type: 'string' },
                        },
                      },
                    ],
                  },
                  type: { type: 'string', enum: ['MC', 'TF'] },
                  order: {
                    type: 'integer',
                    format: 'int64',
                    minimum: -9223372036854776000,
                    maximum: 9223372036854776000,
                  },
                  allowGrading: { type: 'boolean' },
                  points: {
                    type: ['integer', 'null'],
                    format: 'int32',
                    minimum: -2147483648,
                    maximum: 2147483647,
                  },
                  status: {
                    type: 'string',
                    enum: ['OPEN', 'CLOSED', 'LOCKED'],
                  },
                },
              },
            },
            contextXid: { type: ['string', 'null'] },
            contextId: {
              type: ['integer', 'null'],
              format: 'int64',
              minimum: -9223372036854776000,
              maximum: 9223372036854776000,
            },
            status: { type: 'string', enum: ['CREATED', 'STARTED', 'CLOSED'] },
            publicPollId: { type: ['string', 'null'] },
            instanceId: { type: ['integer', 'null'] },
            isDuplicate: {
              type: 'boolean',
              default: false,
              description:
                'Value is true if the poll was copied from same section of a Instructor',
            },
            isCopied: {
              type: 'boolean',
              default: false,
              description:
                'Value is true if the poll was copied from same or different section',
            },
            parentPollId: {
              description:
                'This field identifies the Parent poll from which this poll was copied or duplicated',
              type: ['integer', 'null'],
            },
            reopenCount: {
              description: 'The number of times a poll has been reopened',
              type: 'integer',
            },
          },
        },
      },
      metadata: {
        type: 'object',
        properties: {
          page: { type: 'integer', description: 'Current page of results' },
          perPage: { type: 'integer', description: 'Maximum results per page' },
          pageCount: {
            type: 'integer',
            description: 'Number of results returned for this page',
          },
          totalCount: {
            type: 'integer',
            description: 'Total number of results',
          },
          totalPages: { type: 'integer', description: 'Total number of pages' },
          sort: { type: 'string' },
          order: { type: 'string', enum: ['asc', 'desc'] },
        },
      },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '400': ajv.compile<GetPollsResponseMap['400']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '401': ajv.compile<GetPollsResponseMap['401']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '403': ajv.compile<GetPollsResponseMap['403']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '405': ajv.compile<GetPollsResponseMap['405']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '500': ajv.compile<GetPollsResponseMap['500']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  default: ajv.compile<unknown>({}),
};

function isGetPollsResponse(target: {
  statusCode: HttpStatusCode;
  content: unknown;
}): target is SDKResponseValidation<GetPollsResponseMap> {
  return (
    responseValidators[target.statusCode] ?? responseValidators['default']
  )(target.content);
}
function lastValidationError(statusCode: HttpStatusCode): ErrorObject[] {
  return (
    (responseValidators[statusCode] ?? responseValidators['default']).errors ??
    []
  );
}

export function getPolls(
  http: HttpClient,
  request: GetPollsRequest,
  config: FullConfig
): Observable<GetPollsResponse> {
  const renderedPath = '/api/v1/polls';
  const fullUrl = config.baseUrl + renderedPath;

  const options: Parameters<HttpClient['request']>[2] = {
    responseType: 'blob',
    observe: 'response',
  };

  // Query Parameters
  options.params = {} as Record<string, string | number | boolean>;
  if (request['contextXid'] !== undefined) {
    options.params['contextXid'] = request['contextXid'];
  }
  if (request['page'] !== undefined) {
    options.params['page'] = request['page'];
  }
  if (request['perPage'] !== undefined) {
    options.params['perPage'] = request['perPage'];
  }
  if (request['sort'] !== undefined) {
    options.params['sort'] = request['sort'];
  }
  if (request['order'] !== undefined) {
    options.params['order'] = request['order'];
  }
  if (request['title'] !== undefined) {
    options.params['title'] = request['title'];
  }

  // Headers
  const headerParams: Record<string, string> = {};

  if (config.enableAuthentication && config.tokenSource !== null) {
    headerParams['Authorization'] = `Bearer ${config.tokenSource()}`;
  }
  options.headers = headerParams;

  return http.request('GET', fullUrl, options).pipe(
    config.timeoutInSeconds > 0
      ? timeout({
          first: config.timeoutInSeconds * 1000,
          with: () =>
            throwError(
              new TimeoutError({
                url: fullUrl,
                method: 'GET',
                timeoutInSeconds: config.timeoutInSeconds,
              })
            ),
        })
      : identity,
    wrapErrors('GET', fullUrl),
    retryRequest('GET', config),
    mergeMap(async (response) => {
      const statusCode = String(response.status);
      if (!isHttpStatusCode(statusCode)) {
        throw new InvalidResponseStatusCode('get', fullUrl, statusCode);
      }

      let content: unknown = undefined;

      switch (statusCode) {
        case '200':
          content = await parseResponse(response.body, {
            method: 'get',
            url: fullUrl,
          });
          break;
        case '400':
          content = await parseResponse(response.body, {
            method: 'get',
            url: fullUrl,
          });
          break;
        case '401':
          content = await parseResponse(response.body, {
            method: 'get',
            url: fullUrl,
          });
          break;
        case '403':
          content = await parseResponse(response.body, {
            method: 'get',
            url: fullUrl,
          });
          break;
        case '405':
          content = await parseResponse(response.body, {
            method: 'get',
            url: fullUrl,
          });
          break;
        case '500':
          content = await parseResponse(response.body, {
            method: 'get',
            url: fullUrl,
          });
          break;
        default:
          content = undefined;
          break;
      }

      const responseParts = { statusCode, content };
      if (!isGetPollsResponse(responseParts)) {
        throw new ResponseValidationError(
          'get',
          renderedPath,
          lastValidationError(statusCode),
          statusCode,
          response.body
        );
      }

      config.throwStatus.forEach((range) => {
        if (range.min <= response.status && response.status <= range.max) {
          throw new ResponseStatusCodeOutOfRange({
            body: response.body ?? new Blob(),
            method: 'get',
            url: fullUrl,
            responseStatusCode: statusCode,
          });
        }
      });

      const headers = response.headers
        .keys()
        .reduce((acc: Record<string, string>, key: string) => {
          const val = response.headers.get(key);
          if (val !== null) {
            acc[key] = val;
          }
          return acc;
        }, {} as Record<string, string>);
      return {
        ...responseParts,
        raw: { body: response.body, headers },
      };
    })
  );
}
