<div class="page-container">
  <h1>Launch Instructor View</h1>
  <label for="sectionXid">Section Xid</label>
  <input class="form-control mb-3" id="sectionXid" type="text" />
  <label for="sectionId">Section Id</label>
  <input class="form-control mb-3" id="sectionId" type="text" />
  <label for="userId">User Id</label>
  <input class="form-control mb-3" id="userId" type="text" />
  <label for="personXid">Person Xid</label>
  <input class="form-control mb-3" id="personXid" type="text" />
  <button
    class="btn btn-primary dropdown-toggle"
    type="button"
    id="language-dropdown"
    data-bs-toggle="dropdown"
    aria-expanded="false"
    data-automation-id="language-dropdown-button">
    {{ "PAGE.LANGUAGE" | translate }}
  </button>
  <ul class="dropdown-menu" aria-labelledby="language-dropdown">
    <li>
      <button class="dropdown-item" (click)="changeLanguage(language.English)">
        {{ "LANGUAGE.ENGLISH" | translate }}
      </button>
    </li>
    <li>
      <button class="dropdown-item" (click)="changeLanguage(language.Spanish)">
        {{ "LANGUAGE.SPANISH" | translate }}
      </button>
    </li>
  </ul>
  <br />
  <button
    type="button"
    class="btn btn-primary mb-3 submit"
    (click)="launchPollingTool()">
    Submit
  </button>
</div>
