// ng & libs
import { Inject, Injectable } from '@angular/core';

// etool
import { environment } from 'src/environments/environment';
import { IdentityService } from '../identity/identity.service';
import { PendoCustomPageData } from './pendo.interface';
import { interval, Subject, take, takeUntil } from 'rxjs';
import { OrgData } from '../identity/identity.interface';
import { TranslateService } from '@ngx-translate/core';
import { getUserInfo } from 'src/app/store/selectors/user-info.selectors';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { UserInfoState } from 'src/app/store/state/user-info.state';

interface CustomWindow extends Window {
  pendo: any;
}

@Injectable({
  providedIn: 'root',
})

/**
 * confirm Pendo is working as expected by running pendo.validateInstall() in the browser console
 * https://agent.pendo.io/advanced/location/#pendolocationgethref
 */
export class PendoService {
  userInfo$ = this.store.select(getUserInfo);

  constructor(
    @Inject(Window) private window: CustomWindow,
    private store: Store<AppState>,
    private translateService: TranslateService,
    private identityService: IdentityService
  ) {}

  waitDuration = 1000;
  pendoInitialized = false;
  pendoInitialized$ = new Subject();
  orgData: OrgData = {
    name: null,
    orgXid: 'polling-org-xid-not-found',
  };

  addPendo() {
    this.userInfo$.pipe(take(1)).subscribe({
      next: (userInfoState: UserInfoState) => {
        if (userInfoState.userInfo) {
          this.orgData = {
            name: userInfoState.userInfo?.org?.orgName,
            orgXid: userInfoState.userInfo?.org?.orgXid,
          };
          this.checkAndInitializePendo();
        } else {
          this.identityService
            .getCurrentOrgXid()
            .pipe(take(1))
            .subscribe((response) => {
              if(response) {
                this.orgData = response;
                this.checkAndInitializePendo();  
              }
            });
        }
      },
    });
  }

  checkAndInitializePendo() {
    if (this.isPendoReady()) {
      this.initializePendo();
    } else {
      // attempt to init pendo every second for 30 seconds
      interval(1000)
        .pipe(take(30), takeUntil(this.pendoInitialized$))
        .subscribe((value) => {
          this.initializePendo();
        });
    }
  }

  isPendoReady() : boolean {
    return !!this.window.pendo  && !!this.window.pendo.validateInstall;
  }

  /**
   * returns true if pendo is initialized
   */
  initializePendo(): boolean {
    // This should never happen but just in case I missed something, I don't want an infinite loop
    if (this.pendoInitialized) {
      return true;
    }
    if (!this.isPendoReady()) {
      return false;
    }
    const role = this.identityService.getUserRole();
    const userXid = this.identityService.getPersonXid();
    // see https://mcgrawhill.atlassian.net/l/cp/077kwACa for more info
    const currentLang = this.translateService.currentLang;
    this.window.pendo.initialize({
      visitor: {
        id: userXid,
        environment: environment.envName,
        platform: 'Polling',
        role: role,
        language: currentLang,
        connect_language: currentLang,
        org: this.orgData.orgXid,
      },
      account: {
        id: this.orgData.orgXid,
        name: this.orgData.name,
        // mhe_org_root: '',
        // mhe_org_root_name: '',
      },
    });

    this.pendoInitialized$.next(true);
    this.pendoInitialized = true;
    return true;
  }

  // clear search params
  clearTransforms() {
    window['pendo'].location.addTransforms([
      {
        attr: 'search',
        action: 'Clear',
      },
    ]);
  }

  /**
   * more info: https://agent.pendo.io/advanced/location/#pendolocationgethref
   */
  addStudentPollPageTransform(
    value: PendoCustomPageData = { pollingPage: 'unknown' }
  ) {
    if (!environment.enablePendo) {
      return;
    }
    if (this.pendoInitialized) {
      this.clearTransforms();
      // test with pendo.location.getHref();
      window['pendo'].location.addTransforms([
        {
          attr: 'search',
          action: 'AddTo',
          data: value,
        },
      ]);
    } else {
      const studentPageDataInterval = setInterval(() => {
        if (this.pendoInitialized) {
          this.clearTransforms();
          // test with pendo.location.getHref();
          window['pendo'].location.addTransforms([
            {
              attr: 'search',
              action: 'AddTo',
              data: value,
            },
          ]);
          clearInterval(studentPageDataInterval);
        }
      }, this.waitDuration);
    }
  }
}
