/**
 * Generated by oas-generator
 * Do not modify
 */

import { Metadata } from './Metadata';
import { UserPollScore } from './UserPollScore';
import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface UserPollScoreList {
  data?: Array<UserPollScore>;
  metadata?: Metadata;
}

// schema should have type JSONSchemaType<UserPollScoreList>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    data: {
      type: 'array',
      items: {
        type: 'object',
        description: "This represents user's poll score of a context",
        properties: {
          userId: { type: 'string' },
          userName: { type: 'string' },
          participationScore: {
            type: 'number',
            format: 'double',
            minimum: -1.7976931348623157e308,
            maximum: 1.7976931348623157e308,
          },
          attendanceScore: {
            type: 'integer',
            format: 'int64',
            minimum: -9223372036854776000,
            maximum: 9223372036854776000,
          },
          totalPollScore: {
            type: 'number',
            format: 'double',
            minimum: -1.7976931348623157e308,
            maximum: 1.7976931348623157e308,
          },
          scoreMaximum: {
            type: 'integer',
            format: 'int64',
            minimum: -9223372036854776000,
            maximum: 9223372036854776000,
          },
        },
      },
    },
    metadata: {
      type: 'object',
      properties: {
        page: { type: 'integer', description: 'Current page of results' },
        perPage: { type: 'integer', description: 'Maximum results per page' },
        pageCount: {
          type: 'integer',
          description: 'Number of results returned for this page',
        },
        totalCount: { type: 'integer', description: 'Total number of results' },
        totalPages: { type: 'integer', description: 'Total number of pages' },
        sort: { type: 'string' },
        order: { type: 'string', enum: ['asc', 'desc'] },
      },
    },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isUserPollScoreList = ajv.compile<UserPollScoreList>(schema);
