/**
 * Generated by oas-generator
 * Do not modify
 */

import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface UserContext {
  contextId?: string;
  contextName?: string;
  contextXid?: string;
  pairedWithLMS?: boolean;
}

// schema should have type JSONSchemaType<UserContext>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    contextId: {
      type: ['string', 'null'],
      description: 'context_id is the section id in connect',
    },
    contextName: { type: ['string', 'null'] },
    contextXid: { type: ['string', 'null'] },
    pairedWithLMS: { type: 'boolean' },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isUserContext = ajv.compile<UserContext>(schema);
