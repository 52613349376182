import { Component, HostBinding, OnInit } from '@angular/core';
import { ToastsService } from '../../services/toasts-service/toasts.service';
import { NavigationStart, Router } from '@angular/router';
import '@mhe/mh-icon/src/icon-components/x-circle.js';
import '@mhe/mh-icon/src/icon-components/check-circle.js';
import '@mhe/mh-icon/src/icon-components/info-circle.js';
import '@mhe/mh-icon/src/icon-components/exclamation-triangle-mh.js';

import {
  EtoolToast,
  ToastType,
} from '../../services/toasts-service/toast.interface';

/**
 * @see ToastsService
 *
 */
@Component({
  selector: 'etool-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  @HostBinding('className') hostClasses = 'toast-container position-fixed p-3';

  toastType = ToastType;
  closeButtonElements: NodeListOf<HTMLElement>;
  lastCloseButton: HTMLElement;
  toastsCount = 0;

  constructor(public toastsService: ToastsService, private router: Router) {}

  ngOnInit(): void {
    // To dismiss ERROR toast on navigation
    this.router.events.subscribe((event: NavigationStart) => {
      if (event instanceof NavigationStart) {
        this.toastsService.toasts.forEach((toast) => {
          if (toast.type === ToastType.error) {
            this.toastsService.clear();
          }
        });
      }
    });
  }

  focusOnToastCloseButton(): void {
    this.queryAllToasts();
    if (this.closeButtonElements.length) {
      this.toastsCount = this.closeButtonElements.length;
      this.focusLastToast(this.closeButtonElements.length);
    }
  }

  queryAllToasts() {
    this.closeButtonElements = document.querySelectorAll(
      'ngb-toast button.btn-close'
    );
  }

  focusLastToast(toastsCount: number) {
    if (toastsCount) {
      this.lastCloseButton = this.closeButtonElements[toastsCount - 1];
      this.lastCloseButton?.focus();
    }
  }

  removeToast(toast: EtoolToast) {
    this.toastsService.remove(toast);
    this.toastsCount -= 1;
    this.focusLastToast(this.toastsCount);
  }

  onToastKeyDown(event: KeyboardEvent, index: number) {
    if (event.key === 'Escape') {
      this.removeToast(this.toastsService.toasts[index]);
    }
  }
}
