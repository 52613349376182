/**
 * Generated by oas-generator
 * Do not modify
 */

import { FullConfig } from '../config';
import {
  InvalidResponseStatusCode,
  ResponseStatusCodeOutOfRange,
  ResponseValidationError,
  TimeoutError,
} from '../errors';
import { retryRequest } from '../http/retryRequest';
import {
  HttpStatusCode,
  isHttpStatusCode,
  SDKResponse,
  SDKResponseValidation,
} from '../http/SDKResponse';
import { wrapErrors } from '../http/wrapErrors';
import { QuestionDto } from '../models/QuestionDto';
import { parseResponse } from '../parseResponse';
import { HttpClient, HttpRequest } from '@angular/common/http';
import Ajv, { ErrorObject, ValidateFunction } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';
import instanceofDef from 'ajv-keywords/dist/definitions/instanceof';
import { identity, mergeMap, Observable, throwError, timeout } from 'rxjs';

// Teach AJV about the Blob type
instanceofDef.CONSTRUCTORS['Blob'] = Blob;

export interface GetMultipleQuestionsParameters {
  id: Array<number>;
}

export interface GetMultipleQuestionsRequest
  extends GetMultipleQuestionsParameters {}

export type GetMultipleQuestionsResponse =
  SDKResponse<GetMultipleQuestionsResponseMap>;

type GetMultipleQuestionsResponseMap = {
  '200': Array<QuestionDto>;
  '400': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '401': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '405': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '500': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  default: void;
};

const ajv = new Ajv();
addKeywords(addFormats(ajv));
const responseValidators: Record<string, ValidateFunction> & {
  default: ValidateFunction;
} = {
  '200': ajv.compile<GetMultipleQuestionsResponseMap['200']>({
    type: 'array',
    items: {
      allOf: [
        {
          type: 'object',
          properties: {
            externalQuestionId: {
              allOf: [
                {
                  type: 'object',
                  properties: {
                    collectionId: { type: 'string' },
                    questionId: { type: 'string' },
                  },
                },
              ],
            },
            type: { type: 'string' },
            stem: { type: 'string' },
            title: { type: 'string' },
            choices: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  value: { type: 'string' },
                  isCorrect: { type: 'boolean' },
                },
              },
            },
            status: {
              type: ['string', 'null'],
              enum: ['OPEN', 'CLOSED', 'LOCKED', null],
            },
            id: {
              type: ['integer', 'null'],
              format: 'int64',
              minimum: -9223372036854776000,
              maximum: 9223372036854776000,
            },
            createdOn: { type: ['string', 'null'], format: 'date-time' },
            updatedOn: { type: ['string', 'null'], format: 'date-time' },
            tries: {
              type: ['integer', 'null'],
              format: 'int32',
              minimum: -2147483648,
              maximum: 2147483647,
            },
          },
        },
        { type: 'object', properties: { error: { type: ['string', 'null'] } } },
      ],
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '400': ajv.compile<GetMultipleQuestionsResponseMap['400']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '401': ajv.compile<GetMultipleQuestionsResponseMap['401']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '405': ajv.compile<GetMultipleQuestionsResponseMap['405']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '500': ajv.compile<GetMultipleQuestionsResponseMap['500']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  default: ajv.compile<unknown>({}),
};

function isGetMultipleQuestionsResponse(target: {
  statusCode: HttpStatusCode;
  content: unknown;
}): target is SDKResponseValidation<GetMultipleQuestionsResponseMap> {
  return (
    responseValidators[target.statusCode] ?? responseValidators['default']
  )(target.content);
}
function lastValidationError(statusCode: HttpStatusCode): ErrorObject[] {
  return (
    (responseValidators[statusCode] ?? responseValidators['default']).errors ??
    []
  );
}

export function getMultipleQuestions(
  http: HttpClient,
  request: GetMultipleQuestionsRequest,
  config: FullConfig
): Observable<GetMultipleQuestionsResponse> {
  const renderedPath = '/api/v1/polls/questions/{id}'.replace(
    '{id}',
    String(request['id'])
  );
  const fullUrl = config.baseUrl + renderedPath;

  const options: Parameters<HttpClient['request']>[2] = {
    responseType: 'blob',
    observe: 'response',
  };

  // Headers
  const headerParams: Record<string, string> = {};

  if (config.enableAuthentication && config.tokenSource !== null) {
    headerParams['Authorization'] = `Bearer ${config.tokenSource()}`;
  }
  options.headers = headerParams;

  return http.request('GET', fullUrl, options).pipe(
    config.timeoutInSeconds > 0
      ? timeout({
          first: config.timeoutInSeconds * 1000,
          with: () =>
            throwError(
              new TimeoutError({
                url: fullUrl,
                method: 'GET',
                timeoutInSeconds: config.timeoutInSeconds,
              })
            ),
        })
      : identity,
    wrapErrors('GET', fullUrl),
    retryRequest('GET', config),
    mergeMap(async (response) => {
      const statusCode = String(response.status);
      if (!isHttpStatusCode(statusCode)) {
        throw new InvalidResponseStatusCode('get', fullUrl, statusCode);
      }

      let content: unknown = undefined;

      switch (statusCode) {
        case '200':
          content = await parseResponse(response.body, {
            method: 'get',
            url: fullUrl,
          });
          break;
        case '400':
          content = await parseResponse(response.body, {
            method: 'get',
            url: fullUrl,
          });
          break;
        case '401':
          content = await parseResponse(response.body, {
            method: 'get',
            url: fullUrl,
          });
          break;
        case '405':
          content = await parseResponse(response.body, {
            method: 'get',
            url: fullUrl,
          });
          break;
        case '500':
          content = await parseResponse(response.body, {
            method: 'get',
            url: fullUrl,
          });
          break;
        default:
          content = undefined;
          break;
      }

      const responseParts = { statusCode, content };
      if (!isGetMultipleQuestionsResponse(responseParts)) {
        throw new ResponseValidationError(
          'get',
          renderedPath,
          lastValidationError(statusCode),
          statusCode,
          response.body
        );
      }

      config.throwStatus.forEach((range) => {
        if (range.min <= response.status && response.status <= range.max) {
          throw new ResponseStatusCodeOutOfRange({
            body: response.body ?? new Blob(),
            method: 'get',
            url: fullUrl,
            responseStatusCode: statusCode,
          });
        }
      });

      const headers = response.headers
        .keys()
        .reduce((acc: Record<string, string>, key: string) => {
          const val = response.headers.get(key);
          if (val !== null) {
            acc[key] = val;
          }
          return acc;
        }, {} as Record<string, string>);
      return {
        ...responseParts,
        raw: { body: response.body, headers },
      };
    })
  );
}
