/**
 * Generated by oas-generator
 * Do not modify
 */

import { InjectionToken } from '@angular/core';

export const ConfigToken = new InjectionToken<SDKConfig>(
  'Config for Engagement Tool API SDK Client'
);

export type SDKConfig = Partial<Config> & { baseUrl: string };
export type FullConfig = Config & { baseUrl: string };
export type OperationConfig = Partial<FullConfig>;

export interface Config {
  timeoutInSeconds: number;
  enableAuthentication: boolean;
  tokenSource: null | (() => string);
  throwStatus: { min: number; max: number }[];
  retryEnabled: boolean;
  retryMaximumAttempts: number;
  retryWaitBase: number;
  retryWaitScale: number;
  retryMaxWait: null | number;
}

export const defaultConfig: Config = {
  timeoutInSeconds: 5,
  enableAuthentication: true,
  tokenSource: null,
  throwStatus: [],
  retryEnabled: true,
  retryMaximumAttempts: 3,
  retryWaitBase: 2,
  retryWaitScale: 50,
  retryMaxWait: null,
};
