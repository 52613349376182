/**
 * Generated by oas-generator
 * Do not modify
 */

import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface AnswerChoice {
  value?: string;
  isCorrect?: boolean;
}

// schema should have type JSONSchemaType<AnswerChoice>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: { value: { type: 'string' }, isCorrect: { type: 'boolean' } },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isAnswerChoice = ajv.compile<AnswerChoice>(schema);
