import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageOption } from '../enum/language.enum';

@Component({
  selector: 'etool-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent {
  private destroy$ = new Subject<boolean>();
  readonly language = LanguageOption;

  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    private router: Router,
    private translateService: TranslateService
  ) {}

  launchPollingTool(): void {
    this.fetchTestMHToken()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.cookieService.set(
          'MH_TOKEN',
          res,
          null,
          '/',
          'mheducation.com',
          true
        );
        this.cookieService.set(
          'RESPOND_SECTION_XID',
          (<HTMLInputElement>document.getElementById('sectionXid')).value,
          null,
          '/',
          'mheducation.com',
          true
        );
        this.cookieService.set(
          'RESPOND_SECTION_ID',
          (<HTMLInputElement>document.getElementById('sectionId')).value,
          null,
          '/',
          'mheducation.com',
          true
        );
        this.cookieService.set(
          'RESPOND_USER_ID',
          (<HTMLInputElement>document.getElementById('userId')).value,
          null,
          '/',
          'mheducation.com',
          true
        );
        this.cookieService.set(
          'RESPOND_LANGUAGE',
          this.translateService.currentLang,
          null,
          '/',
          'mheducation.com',
          true
        );
        this.router.navigate(['polls']);
      });
  }

  fetchTestMHToken() {
    const reqUrl = `${environment.connectUrl}/caas/api/v1/sso/token`;
    const body = {
      personXid: (<HTMLInputElement>document.getElementById('personXid')).value,
    };

    return this.http
      .post(reqUrl, body, {
        responseType: 'text',
        headers: new HttpHeaders({ 'X-Skip-Interceptor': '' }),
      })
      .pipe(
        tap((res) => res),
        catchError((err) => {
          console.error('TokenService fetchMHToken error:', err);
          return of(err);
        })
      );
  }

  changeLanguage(language: string) {
    this.translateService.use(language);
  }
}
