/**
 * Generated by oas-generator
 * Do not modify
 */

import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface Response {
  value?: number;
  isCorrect?: boolean;
  hasSubmitted?: boolean;
  submittedOn?: string;
}

// schema should have type JSONSchemaType<Response>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    value: {
      type: ['integer', 'null'],
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    isCorrect: { type: 'boolean' },
    hasSubmitted: { type: 'boolean' },
    submittedOn: { type: ['string', 'null'], format: 'date-time' },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isResponse = ajv.compile<Response>(schema);
