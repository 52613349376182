import { Action } from '@ngrx/store';
import { models } from 'engagement-sdk';

export enum PollsActions {
  LoadPolls = '[Polls] load polls',
  AddPoll = '[Polls] add poll',
  AddQuestionToPoll = '[Polls] add question to Poll',
  RemoveQuestionFromPoll = '[Polls] remove question from Poll',
  DeletePoll = '[Polls] delete poll',
  EditPoll = '[Polls] edit poll',
}

export class LoadPolls implements Action {
  public readonly type = PollsActions.LoadPolls;
  constructor(
    public payload: { data?: models.Poll[]; metadata?: models.Metadata }
  ) {}
}

export class AddPoll implements Action {
  public readonly type = PollsActions.AddPoll;
  constructor(public payload: models.Poll) {}
}

export class AddQuestionToPoll implements Action {
  public readonly type = PollsActions.AddQuestionToPoll;
  constructor(
    public payload: {
      pollId: models.Poll['id'];
      question: models.PollQuestions;
    }
  ) {}
}

export class RemoveQuestionFromPoll implements Action {
  public readonly type = PollsActions.RemoveQuestionFromPoll;
  constructor(
    public payload: {
      pollId: models.Poll['id'];
      questionId: models.PollQuestions['id'];
    }
  ) {}
}

export class DeletePoll implements Action {
  public readonly type = PollsActions.DeletePoll;
  constructor(public payload: number) {}
}

export class EditPoll implements Action {
  public readonly type = PollsActions.EditPoll;
  constructor(public payload: models.Poll) {}
}

export type PollsActionsUnion =
  | LoadPolls
  | AddPoll
  | AddQuestionToPoll
  | EditPoll
  | DeletePoll
  | RemoveQuestionFromPoll;
