/**
 * Generated by oas-generator
 * Do not modify
 */

import { HttpStatusCode } from './http/SDKResponse';
import { ErrorObject } from 'ajv-draft-04';

export class SDKError extends Error {}

export class WrappedError extends SDKError {
  constructor(
    readonly context: { method: string; url: string; upstreamError: Error }
  ) {
    super(
      `${context.method} ${context.url} error: ${context.upstreamError.message}`
    );
  }
}

export class ResponseValidationError extends SDKError {
  constructor(
    readonly method: string,
    readonly url: string,
    readonly errors: ErrorObject[],
    readonly statusCode: string,
    readonly response: Blob | null
  ) {
    super(
      `${method} ${url} response failed validation: ${JSON.stringify(errors)}`
    );
  }
}

export class InvalidResponseStatusCode extends SDKError {
  constructor(
    readonly method: string,
    readonly url: string,
    readonly status: string
  ) {
    super(
      `${method} ${url} responded with an invalid HTTP Status Code: ${status}\nSee https://www.iana.org/assignments/http-status-codes/http-status-codes.xhtml`
    );
  }
}

export class InvalidJsonResponse extends SDKError {
  constructor(
    readonly context: {
      body: string;
      parseError: string;
      method: string;
      url: string;
    }
  ) {
    super(
      `${context.method} ${context.url} responded with an invalid JSON body: ${context.parseError}`
    );
  }
}

export class ResponseStatusCodeOutOfRange extends SDKError {
  constructor(
    readonly context: {
      body: Blob;
      method: string;
      url: string;
      responseStatusCode: HttpStatusCode;
    }
  ) {
    super(
      `${context.method} ${context.url} responded with HTTP status code ${context.responseStatusCode}`
    );
  }
}

export class NetworkError extends SDKError {
  constructor(
    readonly context: { method: string; url: string; upstreamError: string }
  ) {
    super(
      `${context.method} ${context.url} error while establishing a network connection: ${context.upstreamError}`
    );
  }
}

export class TimeoutError extends SDKError {
  constructor(
    readonly context: { method: string; url: string; timeoutInSeconds: number }
  ) {
    super(
      `${context.method} ${context.url} timeout: more than ${context.timeoutInSeconds} seconds elapsed`
    );
  }
}
