/**
 * Generated by oas-generator
 * Do not modify
 */

import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface ContentSource {
  id?: number;
  createdOn?: string;
  updatedOn?: string;
  sourceId?: string;
  name?: string;
}

// schema should have type JSONSchemaType<ContentSource>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'integer',
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    createdOn: { type: 'string', format: 'date-time' },
    updatedOn: { type: 'string', format: 'date-time' },
    sourceId: { type: 'string' },
    name: { type: 'string' },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isContentSource = ajv.compile<ContentSource>(schema);
