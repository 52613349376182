import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  interceptorSkipHeader = 'X-Skip-Interceptor';

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has(this.interceptorSkipHeader)) {
      return next.handle(
        request.clone({
          headers: request.headers.delete(this.interceptorSkipHeader),
        })
      );
    }

    if (
      request.url.includes('assets/i18n') ||
      request.url.includes('assets/quid-i18n') ||
      request.url.includes('assets/proquo-i18n')
    ) {
      return next.handle(request);
    }

    const mHToken = this.cookieService.get('MH_TOKEN');
    const requestWithToken = request.clone({
      setHeaders: {
        Authorization: `Bearer ${mHToken}`,
      },
    });
    return next.handle(requestWithToken);
  }
}

export const TokenProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true,
};
