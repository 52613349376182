import { Action } from "@ngrx/store";
import { models } from "engagement-sdk";

export enum UserInfoAction {
    LoadUserInfo = '[User Info] load user info '
}

export class LoadUserInfo implements Action {
    public readonly type = UserInfoAction.LoadUserInfo;
    constructor(public payload: models.UserInfo) {}
}

export type UserInfoActionsUnion = LoadUserInfo;