/**
 * Generated by oas-generator
 * Do not modify
 */

import { ConfigToken, SDKConfig } from './config';
import { ClientToken } from './custom/CustomClient';
import { CustomNetworkClient } from './custom/CustomNetworkClient';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

@NgModule()
export class SDKModule {
  static forRoot(
    configFactory: (...configDependencies: any[]) => SDKConfig,
    configDependencies: Provider[]
  ): ModuleWithProviders<SDKModule> {
    return {
      ngModule: SDKModule,
      providers: [
        {
          deps: configDependencies,
          provide: ConfigToken,
          useFactory: configFactory,
        },
        { provide: ClientToken, useClass: CustomNetworkClient },
      ],
    };
  }
}
