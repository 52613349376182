import { NetworkClient } from '../NetworkClient';
import { CustomClient } from './CustomClient';
import { Injectable } from '@angular/core';

/**
 * Add custom methods or overwrite generated code here
 */
@Injectable()
export class CustomNetworkClient
  extends NetworkClient
  implements CustomClient {}
