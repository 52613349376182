/**
 * Generated by oas-generator
 * Do not modify
 */

import { FullConfig } from '../config';
import {
  InvalidResponseStatusCode,
  ResponseStatusCodeOutOfRange,
  ResponseValidationError,
  TimeoutError,
} from '../errors';
import { retryRequest } from '../http/retryRequest';
import {
  HttpStatusCode,
  isHttpStatusCode,
  SDKResponse,
  SDKResponseValidation,
} from '../http/SDKResponse';
import { wrapErrors } from '../http/wrapErrors';
import { parseResponse } from '../parseResponse';
import { HttpClient, HttpRequest } from '@angular/common/http';
import Ajv, { ErrorObject, ValidateFunction } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';
import instanceofDef from 'ajv-keywords/dist/definitions/instanceof';
import { identity, mergeMap, Observable, throwError, timeout } from 'rxjs';

// Teach AJV about the Blob type
instanceofDef.CONSTRUCTORS['Blob'] = Blob;

export interface UpdateQuestionStatusOfPollInstanceParameters {
  pollInstanceId: string;
  questionId: string;
  status: 'OPEN' | 'CLOSED';
}

export interface UpdateQuestionStatusOfPollInstanceRequest
  extends UpdateQuestionStatusOfPollInstanceParameters {}

export type UpdateQuestionStatusOfPollInstanceResponse =
  SDKResponse<UpdateQuestionStatusOfPollInstanceResponseMap>;

type UpdateQuestionStatusOfPollInstanceResponseMap = {
  '204': void;
  '400': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '401': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '405': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  '500': {
    message?: string;
    errorCode?: string;
    timestamp?: string;
    trackId?: string;
  };
  default: void;
};

const ajv = new Ajv();
addKeywords(addFormats(ajv));
const responseValidators: Record<string, ValidateFunction> & {
  default: ValidateFunction;
} = {
  '204': ajv.compile<unknown>({}),
  '400': ajv.compile<UpdateQuestionStatusOfPollInstanceResponseMap['400']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '401': ajv.compile<UpdateQuestionStatusOfPollInstanceResponseMap['401']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '405': ajv.compile<UpdateQuestionStatusOfPollInstanceResponseMap['405']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  '500': ajv.compile<UpdateQuestionStatusOfPollInstanceResponseMap['500']>({
    type: 'object',
    properties: {
      message: { type: 'string' },
      errorCode: { type: ['string', 'null'] },
      timestamp: { type: 'string', format: 'date-time' },
      trackId: { type: 'string' },
    },
    $schema: 'http://json-schema.org/draft-04/schema#',
  }),
  default: ajv.compile<unknown>({}),
};

function isUpdateQuestionStatusOfPollInstanceResponse(target: {
  statusCode: HttpStatusCode;
  content: unknown;
}): target is SDKResponseValidation<UpdateQuestionStatusOfPollInstanceResponseMap> {
  return (
    responseValidators[target.statusCode] ?? responseValidators['default']
  )(target.content);
}
function lastValidationError(statusCode: HttpStatusCode): ErrorObject[] {
  return (
    (responseValidators[statusCode] ?? responseValidators['default']).errors ??
    []
  );
}

export function updateQuestionStatusOfPollInstance(
  http: HttpClient,
  request: UpdateQuestionStatusOfPollInstanceRequest,
  config: FullConfig
): Observable<UpdateQuestionStatusOfPollInstanceResponse> {
  const renderedPath =
    '/api/v1/polls/instances/{pollInstanceId}/questions/{questionId}/{status}'
      .replace('{pollInstanceId}', String(request['pollInstanceId']))
      .replace('{questionId}', String(request['questionId']))
      .replace('{status}', String(request['status']));
  const fullUrl = config.baseUrl + renderedPath;

  const options: Parameters<HttpClient['request']>[2] = {
    responseType: 'blob',
    observe: 'response',
  };

  // Headers
  const headerParams: Record<string, string> = {};

  if (config.enableAuthentication && config.tokenSource !== null) {
    headerParams['Authorization'] = `Bearer ${config.tokenSource()}`;
  }
  options.headers = headerParams;

  return http.request('PATCH', fullUrl, options).pipe(
    config.timeoutInSeconds > 0
      ? timeout({
          first: config.timeoutInSeconds * 1000,
          with: () =>
            throwError(
              new TimeoutError({
                url: fullUrl,
                method: 'PATCH',
                timeoutInSeconds: config.timeoutInSeconds,
              })
            ),
        })
      : identity,
    wrapErrors('PATCH', fullUrl),
    retryRequest('PATCH', config),
    mergeMap(async (response) => {
      const statusCode = String(response.status);
      if (!isHttpStatusCode(statusCode)) {
        throw new InvalidResponseStatusCode('patch', fullUrl, statusCode);
      }

      let content: unknown = undefined;

      switch (statusCode) {
        case '204':
          content = undefined;
          break;
        case '400':
          content = await parseResponse(response.body, {
            method: 'patch',
            url: fullUrl,
          });
          break;
        case '401':
          content = await parseResponse(response.body, {
            method: 'patch',
            url: fullUrl,
          });
          break;
        case '405':
          content = await parseResponse(response.body, {
            method: 'patch',
            url: fullUrl,
          });
          break;
        case '500':
          content = await parseResponse(response.body, {
            method: 'patch',
            url: fullUrl,
          });
          break;
        default:
          content = undefined;
          break;
      }

      const responseParts = { statusCode, content };
      if (!isUpdateQuestionStatusOfPollInstanceResponse(responseParts)) {
        throw new ResponseValidationError(
          'patch',
          renderedPath,
          lastValidationError(statusCode),
          statusCode,
          response.body
        );
      }

      config.throwStatus.forEach((range) => {
        if (range.min <= response.status && response.status <= range.max) {
          throw new ResponseStatusCodeOutOfRange({
            body: response.body ?? new Blob(),
            method: 'patch',
            url: fullUrl,
            responseStatusCode: statusCode,
          });
        }
      });

      const headers = response.headers
        .keys()
        .reduce((acc: Record<string, string>, key: string) => {
          const val = response.headers.get(key);
          if (val !== null) {
            acc[key] = val;
          }
          return acc;
        }, {} as Record<string, string>);
      return {
        ...responseParts,
        raw: { body: response.body, headers },
      };
    })
  );
}
