/**
 * Generated by oas-generator
 * Do not modify
 */

import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface PollCopyDto {
  source: {
    sectionXid?: string;
    personXid?: string;
  };
  destination: {
    sectionXid?: string;
    personXid?: string;
  };
}

// schema should have type JSONSchemaType<PollCopyDto>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  required: ['source', 'destination'],
  type: 'object',
  properties: {
    source: {
      type: 'object',
      properties: {
        sectionXid: { type: 'string' },
        personXid: { type: 'string' },
      },
    },
    destination: {
      type: 'object',
      properties: {
        sectionXid: { type: 'string' },
        personXid: { type: 'string' },
      },
    },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isPollCopyDto = ajv.compile<PollCopyDto>(schema);
