import { PollsActions, PollsActionsUnion } from '../actions/polls.actions';
import { InitialPollsState, PollsState } from '../state/polls.state';

export const pollsReducers = (
  state = InitialPollsState,
  action: PollsActionsUnion
): PollsState => {
  switch (action.type) {
    case PollsActions.LoadPolls: {
      return {
        ...state,
        polls: action.payload,
      };
    }

    case PollsActions.AddPoll: {
      return {
        ...state,
        polls: { data: [...state.polls.data, action.payload] },
      };
    }

    case PollsActions.AddQuestionToPoll: {
      const updatedPolls = state.polls.data.map((poll) => {
        // If poll id in the store is same as in the payload then append new question and update the questions array
        if (poll.id === action.payload.pollId) {
          const updatedQuestions = [...poll.questions, action.payload.question];
          return { ...poll, questions: updatedQuestions };
        }
        return poll;
      });
      return {
        ...state,
        polls: { data: updatedPolls },
      };
    }

    case PollsActions.RemoveQuestionFromPoll: {
      const updatedPolls = state.polls.data.map((poll) => {
        // It filters the polls array in the store and removes a specific question from the matching poll based on the payload
        if (poll.id === action.payload.pollId) {
          let updatedQuestions = poll.questions?.filter(
            (question) => question.id !== action.payload.questionId
          );
          return {
            ...poll,
            questions: updatedQuestions,
          };
        }
        return poll;
      });
      return {
        ...state,
        polls: { data: updatedPolls },
      };
    }

    case PollsActions.DeletePoll: {
      const updatedPolls = state.polls.data.filter((poll) => {
        return poll.id !== action.payload;
      });
      return {
        ...state,
        polls: { data: updatedPolls },
      };
    }

    case PollsActions.EditPoll: {
      let updatedPolls = [...state.polls.data];
      const pollIndex = state.polls.data?.findIndex((poll) => {
        return poll.id === action.payload.id;
      });
      updatedPolls.splice(pollIndex, 1, action.payload);
      return {
        ...state,
        polls: { data: updatedPolls },
      };
    }
    default:
      return state;
  }
};
