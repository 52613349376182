/**
 * Generated by oas-generator
 * Do not modify
 */

import { FullConfig } from '../config';
import { HttpResponse } from '@angular/common/http';
import {
  from,
  identity,
  Observable,
  ObservableInput,
  Subscription,
  timer,
} from 'rxjs';

// @see https://www.iana.org/assignments/http-methods/http-methods.xhtml
const IDEMPOTENT_METHODS = [
  'ACL',
  'BASELINE-CONTROL',
  'BIND',
  'CHECKIN',
  'CHECKOUT',
  'COPY',
  'DELETE',
  'GET',
  'HEAD',
  'LABEL',
  'LINK',
  'MERGE',
  'MKACTIVITY',
  'MKCALENDAR',
  'MKCOL',
  'MKREDIRECTREF',
  'MKWORKSPACE',
  'MOVE',
  'OPTIONS',
  'ORDERPATCH',
  'PRI',
  'PROPFIND',
  'PROPPATCH',
  'PUT',
  'REBIND',
  'REPORT',
  'SEARCH',
  'TRACE',
  'UNBIND',
  'UNCHECKOUT',
  'UNLINK',
  'UNLOCK',
  'UPDATE',
  'UPDATEREDIRECTREF',
  'VERSION-CONTROL',
];

const RETRY_STATUS: number[] = [
  408, // Request Timeout
  500, // Internal Server Error
  502, // Bad Gateway
  503, // Service Unavailable
  504, // Gateway Timeout
];

type RetryRequestOperator = (
  observable: Observable<HttpResponse<Blob>>
) => Observable<HttpResponse<Blob>>;
export function retryRequest(
  method: string,
  config: FullConfig
): RetryRequestOperator {
  const enabled =
    config.retryEnabled && IDEMPOTENT_METHODS.includes(method.toUpperCase());
  if (!enabled) {
    return identity;
  }
  return conditionalRetry<HttpResponse<Blob>>(
    (response) => RETRY_STATUS.includes(response.status),
    {
      count: config.retryMaximumAttempts,
      delay: (tryCount) =>
        config.retryWaitScale *
        Math.pow(config.retryWaitBase, Math.max(tryCount - 1, 1)),
    }
  );
}

interface ConditionalRetryConfig<T> {
  count?: number;
  delay?:
    | number
    | ((retryCount: number, val: T) => number | ObservableInput<any>);
}

/**
 * Retry the source observable based on the value emitted.
 *
 * When condition returns true, a new subscription to the source is created and the existing subscription is unsubscribed.
 *
 * Errors and completions are forwarded to the subscriber
 */
function conditionalRetry<T>(
  condition: (val: T, retryCount: number) => boolean,
  config?: ConditionalRetryConfig<T>
): (observable: Observable<T>) => Observable<T> {
  const { count = Number.POSITIVE_INFINITY, delay } = config ?? {};
  return (upstream$) => {
    return new Observable<T>((subscriber) => {
      // State for this operator
      let retryCount = 1;
      let currentSubscription: Subscription | undefined;

      function retry() {
        currentSubscription = upstream$.subscribe({
          complete() {
            subscriber.complete();
          },
          error(err: any) {
            subscriber.error(err);
          },
          next(val: T) {
            const shouldRetry =
              retryCount < (count ?? Number.POSITIVE_INFINITY) &&
              condition(val, retryCount);
            if (!shouldRetry) {
              subscriber.next(val);
              return;
            }

            // Cancel upstream subscription
            if (currentSubscription) currentSubscription.unsubscribe();
            currentSubscription = undefined;

            // Retry with optional delay
            ++retryCount;
            if (delay === undefined) {
              retry();
              return;
            }
            const delayTime =
              typeof delay === 'number' ? delay : delay(retryCount, val);
            const delayNotifier =
              typeof delayTime === 'number'
                ? timer(delayTime)
                : from(delayTime);

            // Retry on first value emitted in notifier observable
            // forward error and complete signals
            const notifierSubscription = delayNotifier.subscribe({
              next() {
                notifierSubscription.unsubscribe();
                retry();
              },
              error(err) {
                subscriber.error(err);
              },
              complete() {
                subscriber.complete();
              },
            });
          },
        });
      }

      retry();
    });
  };
}
