import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TestComponent } from './test/test.component';

const routes: Routes = [
  {
    path: 'polls',
    loadChildren: () =>
      import('./instructor/instructor.module').then((m) => m.InstructorModule),
  },
  {
    path: 'student-results',
    loadChildren: () =>
      import('./students-result/students-result.module').then(
        (m) => m.StudentsResultModule
      ),
  },
  {
    path: 'launch',
    component: TestComponent,
    data: { pageTitleTranslationKey: 'PAGE_TITLE.LAUNCH' },
  },
  {
    path: '',
    loadChildren: () =>
      import('./student/student.module').then((m) => m.StudentModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
