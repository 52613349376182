/**
 * Generated by oas-generator
 * Do not modify
 */

import { AnswerChoice } from './AnswerChoice';
import { ExternalQuestionId } from './ExternalQuestionId';
import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface Question {
  externalQuestionId?: ExternalQuestionId;
  type?: string;
  stem?: string;
  title?: string;
  choices?: Array<AnswerChoice>;
  status?: 'OPEN' | 'CLOSED' | 'LOCKED' | null;
  id?: number;
  createdOn?: string;
  updatedOn?: string;
  tries?: number;
}

// schema should have type JSONSchemaType<Question>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    externalQuestionId: {
      allOf: [
        {
          type: 'object',
          properties: {
            collectionId: { type: 'string' },
            questionId: { type: 'string' },
          },
        },
      ],
    },
    type: { type: 'string' },
    stem: { type: 'string' },
    title: { type: 'string' },
    choices: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          value: { type: 'string' },
          isCorrect: { type: 'boolean' },
        },
      },
    },
    status: {
      type: ['string', 'null'],
      enum: ['OPEN', 'CLOSED', 'LOCKED', null],
    },
    id: {
      type: ['integer', 'null'],
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    createdOn: { type: ['string', 'null'], format: 'date-time' },
    updatedOn: { type: ['string', 'null'], format: 'date-time' },
    tries: {
      type: ['integer', 'null'],
      format: 'int32',
      minimum: -2147483648,
      maximum: 2147483647,
    },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isQuestion = ajv.compile<Question>(schema);
