import { Action } from '@ngrx/store';
import { models } from 'engagement-sdk';

export enum StudentPollActions {
  LoadStudentPoll = '[Student Poll] load poll',
}

export class LoadStudentPoll implements Action {
  public readonly type = StudentPollActions.LoadStudentPoll;
  constructor(public payload: models.Poll) {}
}

export type StudentPollActionsUnion = LoadStudentPoll;
