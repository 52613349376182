import {
  LoadingActions,
  LoadingActionsUnion,
} from '../actions/loading.actions';
import { InitialLoadingState, LoadingState } from '../state/loading.state';

export const loadingReducers = (
  state = InitialLoadingState,
  action: LoadingActionsUnion
): LoadingState => {
  switch (action.type) {
    case LoadingActions.AppSpinner: {
      return {
        ...state,
        isAppLoading: action.payload,
      };
    }
    case LoadingActions.PollWidgetSpinner: {
      return {
        ...state,
        isPollWidgetLoading: action.payload,
      };
    }
    default:
      return state;
  }
};
