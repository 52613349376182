/**
 * Generated by oas-generator
 * Do not modify
 */
import { NetworkError, SDKError, WrappedError } from '../errors';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, of, OperatorFunction, throwError } from 'rxjs';

export function wrapErrors(
  method: string,
  url: string
): OperatorFunction<any, HttpResponse<Blob>> {
  return catchError((err, caught) => {
    if (err instanceof SDKError) {
      return throwError(err);
    }
    if (!(err instanceof HttpErrorResponse)) {
      const upstreamError = err instanceof Error ? err : new Error(String(err));
      return throwError(new WrappedError({ method, url, upstreamError }));
    }
    if (err.status === 0) {
      return throwError(
        new NetworkError({ method, url, upstreamError: err.message })
      );
    }
    const body =
      err.error instanceof Blob ? err.error : new Blob([String(err.error)]);
    return of(
      new HttpResponse({
        body,
        headers: err.headers,
        status: err.status,
        statusText: err.statusText,
        url: err.url ?? undefined,
      })
    );
  });
}
