// ng & libs
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { A11yModule } from '@angular/cdk/a11y';
import { HighchartsChartModule } from 'highcharts-angular';

// etool
import { BsModalComponent } from './components/bs-modal/bs-modal.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ToastComponent } from './components/toast/toast.component';
import { AlertBannerComponent } from './components/alert-banner/alert-banner.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { StripTagsPipe } from './pipes/strip-tags/strip-tags.pipe';
import { PaginateComponent } from '../shared/paginate/paginate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SortIconComponent } from './components/sort-icon/sort-icon.component';
import { HighchartComponent } from './components/highchart/highchart.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
    NgbModule,
    NgbToastModule,
    A11yModule,
    HighchartsChartModule
  ],
  declarations: [
    BsModalComponent,
    ToastComponent,
    SpinnerComponent,
    AlertBannerComponent,
    ErrorPageComponent,
    StripTagsPipe,
    PaginateComponent,
    SortIconComponent,
    HighchartComponent
  ],
  exports: [
    BsModalComponent,
    TranslateModule,
    ToastComponent,
    SpinnerComponent,
    AlertBannerComponent,
    ErrorPageComponent,
    StripTagsPipe,
    PaginateComponent,
    SortIconComponent,
    HighchartComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
