import { Action } from '@ngrx/store';
import { models } from 'engagement-sdk';
import { PollIdWithQuestion } from '../state/questions.state';

export enum QuestionsActions {
  AddQuestion = '[Questions] Add Questions',
  EditQuestion = '[Questions] Edit Question',
  DeleteQuestion = '[Questions] Delete Question',
  LoadQuestions = '[Questions] Load Questions',
}

export class AddQuestion implements Action {
  public readonly type = QuestionsActions.AddQuestion;
  constructor(public payload: PollIdWithQuestion) {}
}

export class EditQuestion implements Action {
  public readonly type = QuestionsActions.EditQuestion;
  constructor(public payload: PollIdWithQuestion) {}
}

export class DeleteQuestion implements Action {
  public readonly type = QuestionsActions.DeleteQuestion;
  constructor(public payload: models.Question['id']) {}
}

export class LoadQuestions implements Action {
  public readonly type = QuestionsActions.LoadQuestions;
  constructor(public payload: PollIdWithQuestion[]) {}
}

export type QuestionsActionsUnion =
  | AddQuestion
  | EditQuestion
  | DeleteQuestion
  | LoadQuestions;
