/**
 * Generated by oas-generator
 * Do not modify
 */

import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface User {
  userXid?: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  defaultRole?: string;
  subRole?: string;
  permissions?: {
    allInstructorAccess?: boolean;
    allStudentAccess?: boolean;
  };
}

// schema should have type JSONSchemaType<User>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: ['object', 'null'],
  properties: {
    userXid: { type: ['string', 'null'] },
    userId: { type: ['string', 'null'] },
    firstName: { type: ['string', 'null'] },
    lastName: { type: ['string', 'null'] },
    defaultRole: { type: ['string', 'null'] },
    subRole: { type: ['string', 'null'] },
    permissions: {
      type: ['object', 'null'],
      properties: {
        allInstructorAccess: { type: 'boolean' },
        allStudentAccess: { type: 'boolean' },
      },
    },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isUser = ajv.compile<User>(schema);
