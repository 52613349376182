import {
  StudentPollActions,
  StudentPollActionsUnion,
} from '../actions/student-poll.actions';
import {
  InitialStudentPollState,
  StudentPollState,
} from '../state/student-poll.state';

export const studentPollReducers = (
  state = InitialStudentPollState,
  action: StudentPollActionsUnion
): StudentPollState => {
  switch (action.type) {
    case StudentPollActions.LoadStudentPoll: {
      return {
        ...state,
        poll: action.payload,
      };
    }
    default:
      return state;
  }
};
