/**
 * Generated by oas-generator
 * Do not modify
 */

import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface Metadata {
  page?: number;
  perPage?: number;
  pageCount?: number;
  totalCount?: number;
  totalPages?: number;
  sort?: string;
  order?: 'asc' | 'desc';
}

// schema should have type JSONSchemaType<Metadata>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    page: { type: 'integer', description: 'Current page of results' },
    perPage: { type: 'integer', description: 'Maximum results per page' },
    pageCount: {
      type: 'integer',
      description: 'Number of results returned for this page',
    },
    totalCount: { type: 'integer', description: 'Total number of results' },
    totalPages: { type: 'integer', description: 'Total number of pages' },
    sort: { type: 'string' },
    order: { type: 'string', enum: ['asc', 'desc'] },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isMetadata = ajv.compile<Metadata>(schema);
