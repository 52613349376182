/**
 * Generated by oas-generator
 * Do not modify
 */

import { Response } from './Response';
import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface UserSubmission {
  userId?: string;
  userName?: string;
  response?: Response;
}

// schema should have type JSONSchemaType<UserSubmission>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    userId: { type: 'string' },
    userName: { type: 'string' },
    response: {
      type: 'object',
      properties: {
        value: {
          type: ['integer', 'null'],
          format: 'int64',
          minimum: -9223372036854776000,
          maximum: 9223372036854776000,
        },
        isCorrect: { type: 'boolean' },
        hasSubmitted: { type: 'boolean' },
        submittedOn: { type: ['string', 'null'], format: 'date-time' },
      },
    },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isUserSubmission = ajv.compile<UserSubmission>(schema);
