import { Component, Input } from '@angular/core';

@Component({
  selector: 'etool-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  /**
   * display spinner as an overlay
   */
  @Input() overlay = true;
  constructor() {}
}
