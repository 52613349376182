/**
 * Generated by oas-generator
 * Do not modify
 */

import { Org } from './Org';
import { User } from './User';
import { UserContext } from './UserContext';
import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface UserInfo {
  platform?: string;
  user?: User;
  context?: UserContext;
  org?: Org;
}

// schema should have type JSONSchemaType<UserInfo>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    platform: { type: 'string' },
    user: {
      type: ['object', 'null'],
      properties: {
        userXid: { type: ['string', 'null'] },
        userId: { type: ['string', 'null'] },
        firstName: { type: ['string', 'null'] },
        lastName: { type: ['string', 'null'] },
        defaultRole: { type: ['string', 'null'] },
        subRole: { type: ['string', 'null'] },
        permissions: {
          type: ['object', 'null'],
          properties: {
            allInstructorAccess: { type: 'boolean' },
            allStudentAccess: { type: 'boolean' },
          },
        },
      },
    },
    context: {
      type: 'object',
      properties: {
        contextId: {
          type: ['string', 'null'],
          description: 'context_id is the section id in connect',
        },
        contextName: { type: ['string', 'null'] },
        contextXid: { type: ['string', 'null'] },
        pairedWithLMS: { type: 'boolean' },
      },
    },
    org: {
      type: ['object', 'null'],
      properties: {
        orgXid: { type: ['string', 'null'] },
        orgId: { type: ['string', 'null'] },
        orgName: { type: ['string', 'null'] },
      },
    },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isUserInfo = ajv.compile<UserInfo>(schema);
