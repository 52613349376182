import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state/app.state';
import {
  AppSpinnerAction,
  PollWidgetSpinnerAction,
} from '../../../store/actions/loading.actions';

const MIN_DISPLAY_MILLISECONDS = 200;
/**
 * see COIRE for original implementation
 *
 */
@Injectable({ providedIn: 'root' })
export class LoadingSpinnerService {
  hasAppLoadingOverlay = false;
  /**
   * by default all requests will show the app spinner
   * the poll widget spinner is included as an example in case we need
   * to use multiple page specific spinners for different requests
   */
  showCount = {
    app: 0,
    poll: 0,
  };
  startTime = {
    app: 0,
    poll: 0,
  };

  constructor(private store: Store<AppState>) {}

  showSpinner(widget: string) {
    if (this.showCount[widget] === 0) {
      setTimeout(() => {
        // workaround for error:
        // "expression has changed after it was checked"
        this.handleLoadingActions(widget, true);
        this.startTime[widget] = performance.now();
      }, 10);
    }

    this.showCount[widget]++;
  }

  hideSpinner(widget) {
    if (this.showCount[widget] === 1) {
      const msSinceStart = performance.now() - this.startTime[widget];
      const msStillNeeded = MIN_DISPLAY_MILLISECONDS - msSinceStart;
      this.reset(msStillNeeded > 0 ? msStillNeeded : 10, widget);
    }

    if (this.showCount[widget] > 0) {
      this.showCount[widget]--;
    }
  }

  reset(delay, widget) {
    setTimeout(() => {
      this.startTime[widget] = 0;
      this.handleLoadingActions(widget, false);
    }, delay);
  }

  handleLoadingActions(widget, show) {
    switch (widget) {
      case 'poll':
        this.store.dispatch(new PollWidgetSpinnerAction(show));
        break;
      default:
        this.store.dispatch(new AppSpinnerAction(show));
    }
  }
}
