import { UserInfoAction, UserInfoActionsUnion } from "../actions/user-info.actions";
import { InitialUserInfoState, UserInfoState } from "../state/user-info.state";

export const UserInfoReducers = (
    state = InitialUserInfoState,
    action: UserInfoActionsUnion
  ): UserInfoState => {
    switch (action.type) {
      case UserInfoAction.LoadUserInfo: {
        return {
          ...state,
          userInfo: action.payload,
        };
      }
      default:
        return state;
    }
  };
  