/**
 * Generated by oas-generator
 * Do not modify
 */

import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface Org {
  orgXid?: string;
  orgId?: string;
  orgName?: string;
}

// schema should have type JSONSchemaType<Org>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: ['object', 'null'],
  properties: {
    orgXid: { type: ['string', 'null'] },
    orgId: { type: ['string', 'null'] },
    orgName: { type: ['string', 'null'] },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isOrg = ajv.compile<Org>(schema);
