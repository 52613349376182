import {
  QuestionsActions,
  QuestionsActionsUnion,
} from '../actions/questions.actions';
import {
  InitialQuestionsState,
  PollIdWithQuestion,
  QuestionsState,
} from '../state/questions.state';

export const questionsReducers = (
  state = InitialQuestionsState,
  action: QuestionsActionsUnion
): QuestionsState => {
  switch (action.type) {
    case QuestionsActions.AddQuestion: {
      return {
        ...state,
        questions: [...state.questions, action.payload],
      };
    }
    case QuestionsActions.EditQuestion: {
      let updatedQuestions = [...state.questions];
      const questionIndex = state.questions?.findIndex((question) => {
        return question.question.id === action.payload.question.id;
      });
      updatedQuestions.splice(questionIndex, 1, action.payload);
      return {
        ...state,
        questions: updatedQuestions,
      };
    }
    case QuestionsActions.DeleteQuestion: {
      const questionsList = state.questions.filter(
        (pollIdWithQuestion: PollIdWithQuestion) => {
          return pollIdWithQuestion.question.id !== action.payload;
        }
      );
      return {
        ...state,
        questions: questionsList,
      };
    }
    case QuestionsActions.LoadQuestions: {
      return {
        ...state,
        questions: action.payload,
      };
    }
    default:
      return state;
  }
};
