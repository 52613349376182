/**
 * Generated by oas-generator
 * Do not modify
 */

import { PollQuestions } from './PollQuestions';
import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface Poll {
  id?: number;
  createdOn?: string;
  updatedOn?: string;
  title: string;
  showQuestionsInorder?: boolean;
  allowAnonymousResponse?: boolean;
  allowStudentViewResponse?: boolean;
  allowStudentViewCorrectAnswer?: boolean;
  displayCorrectAnswerInConductPollView?: boolean;
  scoreForAttendance?: boolean;
  scoreForParticipation?: boolean;
  activeQuestion?: number;
  questions?: Array<PollQuestions>;
  contextXid?: string;
  contextId?: number;
  status?: 'CREATED' | 'STARTED' | 'CLOSED';
  publicPollId?: string;
  instanceId?: number;
  isDuplicate?: boolean;
  isCopied?: boolean;
  parentPollId?: number;
  reopenCount?: number;
}

// schema should have type JSONSchemaType<Poll>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  required: ['title'],
  properties: {
    id: {
      type: 'integer',
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    createdOn: { type: 'string', format: 'date-time' },
    updatedOn: { type: 'string', format: 'date-time' },
    title: { type: 'string' },
    showQuestionsInorder: { type: 'boolean' },
    allowAnonymousResponse: { type: 'boolean' },
    allowStudentViewResponse: { type: 'boolean' },
    allowStudentViewCorrectAnswer: { type: 'boolean' },
    displayCorrectAnswerInConductPollView: { type: 'boolean' },
    scoreForAttendance: { type: 'boolean' },
    scoreForParticipation: { type: 'boolean' },
    activeQuestion: {
      type: ['integer', 'null'],
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    questions: {
      type: ['array', 'null'],
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'integer',
            format: 'int64',
            minimum: -9223372036854776000,
            maximum: 9223372036854776000,
          },
          createdOn: { type: 'string', format: 'date-time' },
          updatedOn: { type: 'string', format: 'date-time' },
          externalQuestionId: {
            type: 'object',
            properties: {
              collectionId: { type: 'string' },
              questionId: { type: 'string' },
            },
          },
          source: {
            anyOf: [
              {},
              {
                type: 'object',
                properties: {
                  id: {
                    type: 'integer',
                    format: 'int64',
                    minimum: -9223372036854776000,
                    maximum: 9223372036854776000,
                  },
                  createdOn: { type: 'string', format: 'date-time' },
                  updatedOn: { type: 'string', format: 'date-time' },
                  sourceId: { type: 'string' },
                  name: { type: 'string' },
                },
              },
            ],
          },
          type: { type: 'string', enum: ['MC', 'TF'] },
          order: {
            type: 'integer',
            format: 'int64',
            minimum: -9223372036854776000,
            maximum: 9223372036854776000,
          },
          allowGrading: { type: 'boolean' },
          points: {
            type: ['integer', 'null'],
            format: 'int32',
            minimum: -2147483648,
            maximum: 2147483647,
          },
          status: { type: 'string', enum: ['OPEN', 'CLOSED', 'LOCKED'] },
        },
      },
    },
    contextXid: { type: ['string', 'null'] },
    contextId: {
      type: ['integer', 'null'],
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    status: { type: 'string', enum: ['CREATED', 'STARTED', 'CLOSED'] },
    publicPollId: { type: ['string', 'null'] },
    instanceId: { type: ['integer', 'null'] },
    isDuplicate: {
      type: 'boolean',
      default: false,
      description:
        'Value is true if the poll was copied from same section of a Instructor',
    },
    isCopied: {
      type: 'boolean',
      default: false,
      description:
        'Value is true if the poll was copied from same or different section',
    },
    parentPollId: {
      description:
        'This field identifies the Parent poll from which this poll was copied or duplicated',
      type: ['integer', 'null'],
    },
    reopenCount: {
      description: 'The number of times a poll has been reopened',
      type: 'integer',
    },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isPoll = ajv.compile<Poll>(schema);
