/**
 * Generated by oas-generator
 * Do not modify
 */

import { Question } from './Question';
import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export type QuestionDto = Question & {
  error?: string;
};

// schema should have type JSONSchemaType<QuestionDto>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  allOf: [
    {
      type: 'object',
      properties: {
        externalQuestionId: {
          allOf: [
            {
              type: 'object',
              properties: {
                collectionId: { type: 'string' },
                questionId: { type: 'string' },
              },
            },
          ],
        },
        type: { type: 'string' },
        stem: { type: 'string' },
        title: { type: 'string' },
        choices: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              value: { type: 'string' },
              isCorrect: { type: 'boolean' },
            },
          },
        },
        status: {
          type: ['string', 'null'],
          enum: ['OPEN', 'CLOSED', 'LOCKED', null],
        },
        id: {
          type: ['integer', 'null'],
          format: 'int64',
          minimum: -9223372036854776000,
          maximum: 9223372036854776000,
        },
        createdOn: { type: ['string', 'null'], format: 'date-time' },
        updatedOn: { type: ['string', 'null'], format: 'date-time' },
        tries: {
          type: ['integer', 'null'],
          format: 'int32',
          minimum: -2147483648,
          maximum: 2147483647,
        },
      },
    },
    { type: 'object', properties: { error: { type: ['string', 'null'] } } },
  ],
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isQuestionDto = ajv.compile<QuestionDto>(schema);
