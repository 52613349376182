import { Client } from '../Client';
import { InjectionToken } from '@angular/core';

export const ClientToken = new InjectionToken<CustomClient>(
  'SDK Client for Engagement Tool API'
);

/**
 * Modify the public interface of the main client class here.
 */
export interface CustomClient extends Client {}
