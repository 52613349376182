/**
 * Generated by oas-generator
 * Do not modify
 */
type ResponseSchema<
  Status,
  ResponseMap extends { default: unknown }
> = Status extends keyof ResponseMap
  ? ResponseMap[Status]
  : ResponseMap['default'];

// Taken from https://www.iana.org/assignments/http-status-codes/http-status-codes.xhtml
export type HttpStatusCode =
  | '100'
  | '101'
  | '102'
  | '103'
  | '200'
  | '201'
  | '202'
  | '203'
  | '204'
  | '205'
  | '206'
  | '207'
  | '208'
  | '226'
  | '300'
  | '301'
  | '302'
  | '303'
  | '304'
  | '305'
  | '306'
  | '307'
  | '308'
  | '400'
  | '401'
  | '402'
  | '403'
  | '404'
  | '405'
  | '406'
  | '407'
  | '408'
  | '409'
  | '410'
  | '411'
  | '412'
  | '413'
  | '414'
  | '415'
  | '416'
  | '417'
  | '421'
  | '422'
  | '423'
  | '424'
  | '425'
  | '426'
  | '427'
  | '428'
  | '429'
  | '430'
  | '431'
  | '451'
  | '500'
  | '501'
  | '502'
  | '503'
  | '504'
  | '505'
  | '506'
  | '507'
  | '508'
  | '509'
  | '510'
  | '511';

export function isHttpStatusCode(code: unknown): code is HttpStatusCode {
  return (
    code === '100' ||
    code === '101' ||
    code === '102' ||
    code === '103' ||
    code === '200' ||
    code === '201' ||
    code === '202' ||
    code === '203' ||
    code === '204' ||
    code === '205' ||
    code === '206' ||
    code === '207' ||
    code === '208' ||
    code === '226' ||
    code === '300' ||
    code === '301' ||
    code === '302' ||
    code === '303' ||
    code === '304' ||
    code === '305' ||
    code === '306' ||
    code === '307' ||
    code === '308' ||
    code === '400' ||
    code === '401' ||
    code === '402' ||
    code === '403' ||
    code === '404' ||
    code === '405' ||
    code === '406' ||
    code === '407' ||
    code === '408' ||
    code === '409' ||
    code === '410' ||
    code === '411' ||
    code === '412' ||
    code === '413' ||
    code === '414' ||
    code === '415' ||
    code === '416' ||
    code === '417' ||
    code === '421' ||
    code === '422' ||
    code === '423' ||
    code === '424' ||
    code === '425' ||
    code === '426' ||
    code === '427' ||
    code === '428' ||
    code === '429' ||
    code === '430' ||
    code === '431' ||
    code === '451' ||
    code === '500' ||
    code === '501' ||
    code === '502' ||
    code === '503' ||
    code === '504' ||
    code === '505' ||
    code === '506' ||
    code === '507' ||
    code === '508' ||
    code === '509' ||
    code === '510' ||
    code === '511'
  );
}

export type SDKResponse<ResponseTypeMap extends { default: unknown }> = {
  [Status in HttpStatusCode]: {
    statusCode: Status;
    raw: {
      body: Blob | null;
      headers: Record<string, string | string[] | undefined>;
    };
    content: ResponseSchema<Status, ResponseTypeMap>;
  };
}[HttpStatusCode];

export type SDKResponseValidation<
  ResponseTypeMap extends { default: unknown }
> = {
  [Status in HttpStatusCode]: {
    statusCode: Status;
    content: ResponseSchema<Status, ResponseTypeMap>;
  };
}[HttpStatusCode];
