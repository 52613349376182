import { ActionReducerMap } from '@ngrx/store';

import { AppState } from '../state/app.state';
import { pollsReducers } from './polls.reducers';
import { loadingReducers } from './loading.reducer';
import { questionsReducers } from './questions.reducers';
import { studentPollReducers } from './student-poll.reducers';
import { UserInfoReducers } from './user-info.reducers';

export const appReducers: ActionReducerMap<AppState, any> = {
  pollState: pollsReducers,
  loading: loadingReducers,
  questionsState: questionsReducers,
  studentPollState: studentPollReducers,
  userInfoState: UserInfoReducers,
};
