/**
 * Generated by oas-generator
 * Do not modify
 */

import { UserResponse } from './UserResponse';
import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface UserPollInstance {
  id?: number;
  createdOn?: string;
  updatedOn?: string;
  pollInstanceId: number;
  responses: Array<UserResponse>;
}

// schema should have type JSONSchemaType<UserPollInstance>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  required: ['pollInstanceId', 'responses'],
  type: 'object',
  properties: {
    id: {
      type: 'integer',
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    createdOn: { type: 'string', format: 'date-time' },
    updatedOn: { type: 'string', format: 'date-time' },
    pollInstanceId: {
      type: 'integer',
      format: 'int64',
      minimum: 1,
      maximum: 9223372036854776000,
    },
    responses: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'integer',
            format: 'int64',
            minimum: -9223372036854776000,
            maximum: 9223372036854776000,
          },
          createdOn: { type: 'string', format: 'date-time' },
          updatedOn: { type: 'string', format: 'date-time' },
          internalQuestionId: {
            type: 'integer',
            format: 'int64',
            minimum: -9223372036854776000,
            maximum: 9223372036854776000,
          },
          choices: {
            type: 'array',
            items: {
              type: 'integer',
              format: 'int32',
              minimum: -2147483648,
              maximum: 2147483647,
            },
          },
        },
      },
    },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isUserPollInstance = ajv.compile<UserPollInstance>(schema);
