/**
 * Generated by oas-generator
 * Do not modify
 */

import { ResultsSummary } from './ResultsSummary';
import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface PollResults {
  id?: number;
  createdOn?: string;
  updatedOn?: string;
  pollInstanceId?: number;
  internalQuestionId?: number;
  summary?: Array<ResultsSummary>;
}

// schema should have type JSONSchemaType<PollResults>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    id: {
      type: ['integer', 'null'],
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    createdOn: { type: ['string', 'null'], format: 'date-time' },
    updatedOn: { type: ['string', 'null'], format: 'date-time' },
    pollInstanceId: {
      type: 'integer',
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    internalQuestionId: {
      type: 'integer',
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    summary: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          choiceId: {
            type: 'integer',
            format: 'int64',
            minimum: -9223372036854776000,
            maximum: 9223372036854776000,
          },
          totalSelection: {
            type: 'integer',
            format: 'int64',
            minimum: -9223372036854776000,
            maximum: 9223372036854776000,
          },
        },
      },
    },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isPollResults = ajv.compile<PollResults>(schema);
