/**
 * Generated by oas-generator
 * Do not modify
 */

import { ContentSource } from './ContentSource';
import { ExternalQuestionId } from './ExternalQuestionId';
import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface PollQuestions {
  id?: number;
  createdOn?: string;
  updatedOn?: string;
  externalQuestionId?: ExternalQuestionId;
  source?: unknown | ContentSource | (unknown & ContentSource);
  type?: 'MC' | 'TF';
  order?: number;
  allowGrading?: boolean;
  points?: number;
  status?: 'OPEN' | 'CLOSED' | 'LOCKED';
}

// schema should have type JSONSchemaType<PollQuestions>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'integer',
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    createdOn: { type: 'string', format: 'date-time' },
    updatedOn: { type: 'string', format: 'date-time' },
    externalQuestionId: {
      type: 'object',
      properties: {
        collectionId: { type: 'string' },
        questionId: { type: 'string' },
      },
    },
    source: {
      anyOf: [
        {},
        {
          type: 'object',
          properties: {
            id: {
              type: 'integer',
              format: 'int64',
              minimum: -9223372036854776000,
              maximum: 9223372036854776000,
            },
            createdOn: { type: 'string', format: 'date-time' },
            updatedOn: { type: 'string', format: 'date-time' },
            sourceId: { type: 'string' },
            name: { type: 'string' },
          },
        },
      ],
    },
    type: { type: 'string', enum: ['MC', 'TF'] },
    order: {
      type: 'integer',
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    allowGrading: { type: 'boolean' },
    points: {
      type: ['integer', 'null'],
      format: 'int32',
      minimum: -2147483648,
      maximum: 2147483647,
    },
    status: { type: 'string', enum: ['OPEN', 'CLOSED', 'LOCKED'] },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isPollQuestions = ajv.compile<PollQuestions>(schema);
