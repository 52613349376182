/**
 * Generated by oas-generator
 * Do not modify
 */

import { InvalidJsonResponse } from './errors';

export async function parseResponse(
  body: Blob | null,
  context: { method: string; url: string }
): Promise<unknown> {
  if (body === null || body.size === 0) {
    return undefined;
  }
  let raw = await body.text();

  // Remove leading BOM if present because JSON.parse fails with it present
  if (raw.charCodeAt(0) === 0xfeff) {
    raw = raw.slice(1);
  }

  try {
    return JSON.parse(raw);
  } catch (err) {
    throw new InvalidJsonResponse({
      body: raw,
      parseError: String(err),
      ...context,
    });
  }
}
