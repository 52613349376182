/**
 * Generated by oas-generator
 * Do not modify
 */

import { PollCopyDto } from './PollCopyDto';
import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export type SinglePollCopy = PollCopyDto & {
  copyProcessReference?: number;
  pollId: number;
};

// schema should have type JSONSchemaType<SinglePollCopy>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  allOf: [
    {
      required: ['source', 'destination'],
      type: 'object',
      properties: {
        source: {
          type: 'object',
          properties: {
            sectionXid: { type: 'string' },
            personXid: { type: 'string' },
          },
        },
        destination: {
          type: 'object',
          properties: {
            sectionXid: { type: 'string' },
            personXid: { type: 'string' },
          },
        },
      },
    },
    {
      type: 'object',
      properties: {
        copyProcessReference: {
          type: 'integer',
          format: 'int64',
          minimum: -9223372036854776000,
          maximum: 9223372036854776000,
        },
        pollId: {
          type: 'integer',
          format: 'int64',
          minimum: -9223372036854776000,
          maximum: 9223372036854776000,
        },
      },
      required: ['pollId'],
    },
  ],
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isSinglePollCopy = ajv.compile<SinglePollCopy>(schema);
