import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import jwt_decode from 'jwt-decode';

import { LoginModeType } from 'src/app/enum/preview-mode.enum';
import { OrgData } from './identity.interface';
import {
  Client,
  ClientToken,
  GetUserInfoRequest,
  GetUserInfoResponse,
  models,
} from 'engagement-sdk';
import { LoadUserInfo } from 'src/app/store/actions/user-info.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { ToastsService } from '../toasts-service/toasts.service';
import { UserInfoState } from 'src/app/store/state/user-info.state';
import { getUserInfo } from 'src/app/store/selectors/user-info.selectors';

@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  mhToken = new BehaviorSubject(null);
  sectionXid = new BehaviorSubject(null);
  sectionId = new BehaviorSubject(null);
  userInfo = new BehaviorSubject(null);
  isUserInfoLoaded = new BehaviorSubject<boolean | null>(null);
  destroy$ = new Subject<boolean>();

  userInfo$ = this.store.select(getUserInfo);

  userSectionXid = '';

  constructor(
    @Inject(ClientToken) private sdk: Client,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private toastsService: ToastsService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  getMhTokenValue(): unknown {
    const mhtokenCookie = this.cookieService.get('MH_TOKEN');  
    if(!mhtokenCookie) {
      return null;
    }
    try {
      const decodedToken = jwt_decode(mhtokenCookie, { header: false });
      return decodedToken;
    } catch (error) {
      return null;
    }
  }

  userDataChanges() {
    return this.userInfo;
  }

  getPersonXid(): string {
    if (!this.mhToken.getValue()) {
      this.mhToken.next(this.getMhTokenValue());
    }
    return this.mhToken.getValue()?.person_xid;
  }

  getSectionXid(): string {
    if (!this.sectionXid.getValue()) {
      const cookie = this.cookieService.get('RESPOND_SECTION_XID');
      if (cookie) {
        return decodeURI(cookie);
      } else {
        return null;
      }
    }
    return this.sectionXid.getValue();
  }

  getSectionID(): string {
    if (!this.sectionId.getValue()) {
      const cookie = this.cookieService.get('RESPOND_SECTION_ID');
      if (cookie) {
        return decodeURI(cookie);
      } else {
        return null;
      }
    }
    return this.sectionId.getValue();
  }

  getUserRole() {
    // check userInfo first because the MH_TOKEN won't have a role if instructor is launching from an LMS
    if (this.userInfo.getValue()) {
      const roleFromUserInfo = this.userInfo.getValue().user.defaultRole;
      return roleFromUserInfo ? roleFromUserInfo.toLowerCase() : this.getUserRoleFromToken();
    } else {
      return this.getUserRoleFromToken();
    }
  }

  getUserSubRole() {
    if (this.userInfo.getValue()) {
      return this.userInfo.getValue().user.subRole;
    }
  }

  getUserRoleFromToken() {
    if (!this.mhToken.getValue()) {
      this.mhToken.next(this.getMhTokenValue());
    }
    const roleFromToken = this.mhToken.getValue()?.['default_role'];
    return roleFromToken ? roleFromToken.toLowerCase() : 'unknown';
  }

  verifyRoleIsInstructor(): boolean {
    try {
      if (this.getUserRole() === LoginModeType.instructor) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  getCurrentOrgXid(): Observable<OrgData> {
    this.userSectionXid = this.getSectionXid();
    if (this.userSectionXid) {
      return this.checkUserInfoLoaded().pipe(
        filter((isLoaded: boolean) => {
          if (isLoaded == null) {
            return false;
          } else if (isLoaded) {
            return true;
          } else {
            return false;
          }
        }),
        switchMap(() => this.userInfo$),
        takeUntil(this.destroy$),
        map((userInfoState: UserInfoState) => {
          const orgData: OrgData = {
            orgXid: userInfoState.userInfo?.org?.orgXid,
            name: userInfoState.userInfo?.org?.orgName,
          };
          return orgData;
        })
      );
    } else {
      return of({ orgXid: 'polling-test-account-id', name: null });
    }
  }

  initUserInfo() {
    //TODO: edit this check for sectionXid once backend creates new consolidated cookie
    this.userSectionXid = this.getSectionXid();
    if (this.userSectionXid) {
      this.getUserInfo({ contextXid: this.userSectionXid })
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            this.userInfo.next(data);
            this.isUserInfoLoaded.next(true);
          },
          error: (err) => {
            this.isUserInfoLoaded.next(false);
          },
        });
    }
  }

  getUserInfo(request: GetUserInfoRequest): Observable<models.UserInfo> {
    return this.sdk.getUserInfo(request).pipe(
      map((response: GetUserInfoResponse) => {
        if (response.statusCode === '200') {
          this.store.dispatch(new LoadUserInfo(response.content));
          return response.content;
        }
        throw new Error(response.statusCode);
      })
    );
  }

  checkUserInfoLoaded(): Observable<boolean> {
    return this.isUserInfoLoaded.asObservable();
  }
}
