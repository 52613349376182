/**
 * Generated by oas-generator
 * Do not modify
 */

import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface UserPollScoreDto {
  contextName?: string;
  participationScore?: number;
  attendanceScore?: number;
  totalPollScore?: number;
  scoreMaximum?: number;
  isScored?: boolean;
}

// schema should have type JSONSchemaType<UserPollScoreDto>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    contextName: { type: 'string' },
    participationScore: {
      type: 'number',
      format: 'double',
      minimum: -1.7976931348623157e308,
      maximum: 1.7976931348623157e308,
    },
    attendanceScore: {
      type: 'integer',
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    totalPollScore: {
      type: 'number',
      format: 'double',
      minimum: -1.7976931348623157e308,
      maximum: 1.7976931348623157e308,
    },
    scoreMaximum: {
      type: 'number',
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    isScored: { type: 'boolean' },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isUserPollScoreDto = ajv.compile<UserPollScoreDto>(schema);
