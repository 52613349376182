/**
 * Generated by oas-generator
 * Do not modify
 */

import Ajv, { JSONSchemaType } from 'ajv-draft-04';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';

export interface ResultsSummary {
  choiceId?: number;
  totalSelection?: number;
}

// schema should have type JSONSchemaType<ResultsSummary>
// except Ajv doesn't fully support optional parameters
// @see https://github.com/ajv-validator/ajv/issues/1664
const schema = {
  type: 'object',
  properties: {
    choiceId: {
      type: 'integer',
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
    totalSelection: {
      type: 'integer',
      format: 'int64',
      minimum: -9223372036854776000,
      maximum: 9223372036854776000,
    },
  },
  $schema: 'http://json-schema.org/draft-04/schema#',
};
const ajv = new Ajv();
addKeywords(addFormats(ajv));
export const isResultsSummary = ajv.compile<ResultsSummary>(schema);
