import { models } from 'engagement-sdk';

export interface PollIdWithQuestion {
  question: models.Question;
  pollId: number;
}

export interface QuestionsState {
  questions: PollIdWithQuestion[];
}

export const InitialQuestionsState: QuestionsState = {
  questions: [],
};
